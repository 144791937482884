import React, { useEffect, useState } from "react";
import { classNames } from "./classNames";
import "../../assets/styles/index.css";
import "../../assets/styles/tailwind.css";

export default function TopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className="fixed bottom-2 right-2">
      <button
        className={classNames(
          isVisible ? "opacity-100" : "opacity-0",
          "bg-indigo-400 hover:bg-indigo-800 inline-flex items-center rounded px-3 py-3 text-white shadow-sm transition-opacity"
        )}
        onClick={scrollToTop}
        type="button"
      >
        <i className="fa-solid fa-arrow-up"></i>
      </button>
    </div>
  );
}
