import React from "react";
import { Link } from "react-router-dom";
import appstore from "../../assets/img/main/App_Store_Badge_JP.png";
import googleplay from "../../assets/img/main/google-play-badge.png";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-blueGray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full border-0 absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-xl md:text-3xl font-semibold text-blueGray-600">
                いつでもどこでもDanGoTalk
              </h4>
              <h5 className="text-sm md:text-lg mt-0 mb-2 text-blueGray-600">
                気になる情報を生の声で
              </h5>
              <div className="mt-12">
                <a
                  href="https://apps.apple.com/jp/app/dangotalk/id6444250993"
                  className="inline-block"
                >
                  <img
                    alt="Appstore でダウンロード"
                    src={appstore}
                    className="mr-3 w-[100%] max-w-100-px max-h-30-px"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dangotalk.app&hl=ja&gl=US"
                  className="inline-block ml-5"
                >
                  <img
                    alt="Google Play で手に入れよう"
                    src={googleplay}
                    className="w-[100%] max-w-100-px max-h-30-px"
                  />
                </a>
              </div>
              <div className="mt-6 lg:mb-0 mb-6">
                <Link to="https://twitter.com/daOn_0515" target="_blank">
                  <i className="fab fa-twitter mr-2 text-blueGray-800 hover:text-blueGray-500 text-xl"></i>
                </Link>
                <Link
                  to="https://www.facebook.com/profile.php?id=100089911123911"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square mr-2 text-blueGray-800 hover:text-blueGray-500 text-xl"></i>
                </Link>
                <Link to="https://instagram.com/dangotalk" target="_blank">
                  <i className="fa-brands fa-instagram mr-2 text-blueGray-800 hover:text-blueGray-500 text-xl"></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/in/dango-talk-891027257/"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin text-blueGray-800 hover:text-blueGray-500 text-xl"></i>
                </Link>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/faq"
                      >
                        よくあるご質問
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/step"
                      >
                        ご利用の流れ
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/contact"
                      >
                        お問い合わせ
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                    Other Resources
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/conditions"
                      >
                        利用規約
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/privacy"
                      >
                        プライバシー
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/commerce"
                      >
                        特定商取引法に基づく表記
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-blueGray-800 hover:text-blueGray-500 font-semibold block pb-2 text-xs"
                        to="/cancellation"
                      >
                        キャンセルポリシー
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © {new Date().getFullYear()} DanGoTalk
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
