/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      fcmToken
      platformDeviceId
      nickname
      familyName
      firstName
      familyNameKana
      firstNameKana
      birthdate
      countryCode
      phoneNumber
      profileUrl
      timeZone
      squareCustomerId
      withdraw
      reservedUser {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      reservedAdvisor {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      userAdvisorId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      fcmToken
      platformDeviceId
      nickname
      familyName
      firstName
      familyNameKana
      firstNameKana
      birthdate
      countryCode
      phoneNumber
      profileUrl
      timeZone
      squareCustomerId
      withdraw
      reservedUser {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      reservedAdvisor {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      userAdvisorId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      fcmToken
      platformDeviceId
      nickname
      familyName
      firstName
      familyNameKana
      firstNameKana
      birthdate
      countryCode
      phoneNumber
      profileUrl
      timeZone
      squareCustomerId
      withdraw
      reservedUser {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      reservedAdvisor {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      userAdvisorId
    }
  }
`;
export const createAdvisor = /* GraphQL */ `
  mutation CreateAdvisor(
    $input: CreateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    createAdvisor(input: $input, condition: $condition) {
      id
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      comment
      hashtags
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      isViewAdvisorInfo
      advisorTimesheet {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          tableType
          isFixedTimeSetting
          dayOfWeek
          startTime
          endTime
          isVisibility
          updatedAt
          createdAt
          advisorAdvisorTimesheetId
        }
        nextToken
      }
      questions {
        label
        value
      }
      timePrice
      talkCount
      reviewScore
      reviewCount
      favoriteCount
      advisorFinancialInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        bankAccountInfoId
        lastDepositStatus
        lastDepositDate
        cumulativeAmount
        presentBalance
        systemCommission
        createdAt
        updatedAt
        advisorFinancialInfoAdvisorId
      }
      searchNickname
      searchCompany
      searchJobTypeMiddle
      searchCertificate0AuthStatus
      searchCertificate1AuthStatus
      searchCertificate2AuthStatus
      searchCertificate3AuthStatus
      searchCertificate4AuthStatus
      withdraw
      createdAt
      updatedAt
      advisorAdvisorFinancialInfoId
      advisorUserId
    }
  }
`;
export const updateAdvisor = /* GraphQL */ `
  mutation UpdateAdvisor(
    $input: UpdateAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    updateAdvisor(input: $input, condition: $condition) {
      id
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      comment
      hashtags
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      isViewAdvisorInfo
      advisorTimesheet {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          tableType
          isFixedTimeSetting
          dayOfWeek
          startTime
          endTime
          isVisibility
          updatedAt
          createdAt
          advisorAdvisorTimesheetId
        }
        nextToken
      }
      questions {
        label
        value
      }
      timePrice
      talkCount
      reviewScore
      reviewCount
      favoriteCount
      advisorFinancialInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        bankAccountInfoId
        lastDepositStatus
        lastDepositDate
        cumulativeAmount
        presentBalance
        systemCommission
        createdAt
        updatedAt
        advisorFinancialInfoAdvisorId
      }
      searchNickname
      searchCompany
      searchJobTypeMiddle
      searchCertificate0AuthStatus
      searchCertificate1AuthStatus
      searchCertificate2AuthStatus
      searchCertificate3AuthStatus
      searchCertificate4AuthStatus
      withdraw
      createdAt
      updatedAt
      advisorAdvisorFinancialInfoId
      advisorUserId
    }
  }
`;
export const deleteAdvisor = /* GraphQL */ `
  mutation DeleteAdvisor(
    $input: DeleteAdvisorInput!
    $condition: ModelAdvisorConditionInput
  ) {
    deleteAdvisor(input: $input, condition: $condition) {
      id
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      comment
      hashtags
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      isViewAdvisorInfo
      advisorTimesheet {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          tableType
          isFixedTimeSetting
          dayOfWeek
          startTime
          endTime
          isVisibility
          updatedAt
          createdAt
          advisorAdvisorTimesheetId
        }
        nextToken
      }
      questions {
        label
        value
      }
      timePrice
      talkCount
      reviewScore
      reviewCount
      favoriteCount
      advisorFinancialInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        bankAccountInfoId
        lastDepositStatus
        lastDepositDate
        cumulativeAmount
        presentBalance
        systemCommission
        createdAt
        updatedAt
        advisorFinancialInfoAdvisorId
      }
      searchNickname
      searchCompany
      searchJobTypeMiddle
      searchCertificate0AuthStatus
      searchCertificate1AuthStatus
      searchCertificate2AuthStatus
      searchCertificate3AuthStatus
      searchCertificate4AuthStatus
      withdraw
      createdAt
      updatedAt
      advisorAdvisorFinancialInfoId
      advisorUserId
    }
  }
`;
export const createAdvisorInfo = /* GraphQL */ `
  mutation CreateAdvisorInfo(
    $input: CreateAdvisorInfoInput!
    $condition: ModelAdvisorInfoConditionInput
  ) {
    createAdvisorInfo(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      order
      isContentCompleted
      company
      companyNameMaster {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        sequenceNumber
        corporateNumber
        process
        correct
        updateDate
        changeDate
        name
        nameImageId
        kind
        prefectureName
        cityName
        streetNumber
        addressImageId
        prefectureCode
        cityCode
        postCode
        addressOutside
        addressOutsideImageId
        closeDate
        closeCause
        successorCorporateNumber
        changeCause
        assignmentDate
        latest
        enName
        enPrefectureName
        enCityName
        enAddressOutside
        furigana
        hihyoji
        registeredCount
        version
        createdAt
        updatedAt
      }
      jobType {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        jobTypeLarge
        jobTypeMiddle
        jobTypeLargeDescription
        jobTypeMiddleDescription
        jobTypeMiddleCount
        createdAt
        updatedAt
      }
      description
      yearsOfExperience
      isCompanyVisibility
      career {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        jobTypeLarge
        jobTypeMiddle
        jobGrade
        joiningDate
        leavingDate
        updatedAt
        createdAt
        careerAdvisorInfoId
      }
      authentication {
        items {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          order
          authType
          authStatus
          documentUrl
          adminName
          adminComment
          updatedAt
          createdAt
          advisorInfoAuthenticationId
        }
        nextToken
      }
      updatedAt
      createdAt
      advisorAdvisorInfoId
      companyNameMasterAdvisorInfoId
      jobTypeAdvisorInfoId
      advisorInfoCareerId
    }
  }
`;
export const updateAdvisorInfo = /* GraphQL */ `
  mutation UpdateAdvisorInfo(
    $input: UpdateAdvisorInfoInput!
    $condition: ModelAdvisorInfoConditionInput
  ) {
    updateAdvisorInfo(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      order
      isContentCompleted
      company
      companyNameMaster {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        sequenceNumber
        corporateNumber
        process
        correct
        updateDate
        changeDate
        name
        nameImageId
        kind
        prefectureName
        cityName
        streetNumber
        addressImageId
        prefectureCode
        cityCode
        postCode
        addressOutside
        addressOutsideImageId
        closeDate
        closeCause
        successorCorporateNumber
        changeCause
        assignmentDate
        latest
        enName
        enPrefectureName
        enCityName
        enAddressOutside
        furigana
        hihyoji
        registeredCount
        version
        createdAt
        updatedAt
      }
      jobType {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        jobTypeLarge
        jobTypeMiddle
        jobTypeLargeDescription
        jobTypeMiddleDescription
        jobTypeMiddleCount
        createdAt
        updatedAt
      }
      description
      yearsOfExperience
      isCompanyVisibility
      career {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        jobTypeLarge
        jobTypeMiddle
        jobGrade
        joiningDate
        leavingDate
        updatedAt
        createdAt
        careerAdvisorInfoId
      }
      authentication {
        items {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          order
          authType
          authStatus
          documentUrl
          adminName
          adminComment
          updatedAt
          createdAt
          advisorInfoAuthenticationId
        }
        nextToken
      }
      updatedAt
      createdAt
      advisorAdvisorInfoId
      companyNameMasterAdvisorInfoId
      jobTypeAdvisorInfoId
      advisorInfoCareerId
    }
  }
`;
export const deleteAdvisorInfo = /* GraphQL */ `
  mutation DeleteAdvisorInfo(
    $input: DeleteAdvisorInfoInput!
    $condition: ModelAdvisorInfoConditionInput
  ) {
    deleteAdvisorInfo(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      order
      isContentCompleted
      company
      companyNameMaster {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        sequenceNumber
        corporateNumber
        process
        correct
        updateDate
        changeDate
        name
        nameImageId
        kind
        prefectureName
        cityName
        streetNumber
        addressImageId
        prefectureCode
        cityCode
        postCode
        addressOutside
        addressOutsideImageId
        closeDate
        closeCause
        successorCorporateNumber
        changeCause
        assignmentDate
        latest
        enName
        enPrefectureName
        enCityName
        enAddressOutside
        furigana
        hihyoji
        registeredCount
        version
        createdAt
        updatedAt
      }
      jobType {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        jobTypeLarge
        jobTypeMiddle
        jobTypeLargeDescription
        jobTypeMiddleDescription
        jobTypeMiddleCount
        createdAt
        updatedAt
      }
      description
      yearsOfExperience
      isCompanyVisibility
      career {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        jobTypeLarge
        jobTypeMiddle
        jobGrade
        joiningDate
        leavingDate
        updatedAt
        createdAt
        careerAdvisorInfoId
      }
      authentication {
        items {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          order
          authType
          authStatus
          documentUrl
          adminName
          adminComment
          updatedAt
          createdAt
          advisorInfoAuthenticationId
        }
        nextToken
      }
      updatedAt
      createdAt
      advisorAdvisorInfoId
      companyNameMasterAdvisorInfoId
      jobTypeAdvisorInfoId
      advisorInfoCareerId
    }
  }
`;
export const createAdvisorTimesheet = /* GraphQL */ `
  mutation CreateAdvisorTimesheet(
    $input: CreateAdvisorTimesheetInput!
    $condition: ModelAdvisorTimesheetConditionInput
  ) {
    createAdvisorTimesheet(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      tableType
      isFixedTimeSetting
      dayOfWeek
      startTime
      endTime
      isVisibility
      updatedAt
      createdAt
      advisorAdvisorTimesheetId
    }
  }
`;
export const updateAdvisorTimesheet = /* GraphQL */ `
  mutation UpdateAdvisorTimesheet(
    $input: UpdateAdvisorTimesheetInput!
    $condition: ModelAdvisorTimesheetConditionInput
  ) {
    updateAdvisorTimesheet(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      tableType
      isFixedTimeSetting
      dayOfWeek
      startTime
      endTime
      isVisibility
      updatedAt
      createdAt
      advisorAdvisorTimesheetId
    }
  }
`;
export const deleteAdvisorTimesheet = /* GraphQL */ `
  mutation DeleteAdvisorTimesheet(
    $input: DeleteAdvisorTimesheetInput!
    $condition: ModelAdvisorTimesheetConditionInput
  ) {
    deleteAdvisorTimesheet(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      tableType
      isFixedTimeSetting
      dayOfWeek
      startTime
      endTime
      isVisibility
      updatedAt
      createdAt
      advisorAdvisorTimesheetId
    }
  }
`;
export const createCareer = /* GraphQL */ `
  mutation CreateCareer(
    $input: CreateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    createCareer(input: $input, condition: $condition) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      jobTypeLarge
      jobTypeMiddle
      jobGrade
      joiningDate
      leavingDate
      updatedAt
      createdAt
      careerAdvisorInfoId
    }
  }
`;
export const updateCareer = /* GraphQL */ `
  mutation UpdateCareer(
    $input: UpdateCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    updateCareer(input: $input, condition: $condition) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      jobTypeLarge
      jobTypeMiddle
      jobGrade
      joiningDate
      leavingDate
      updatedAt
      createdAt
      careerAdvisorInfoId
    }
  }
`;
export const deleteCareer = /* GraphQL */ `
  mutation DeleteCareer(
    $input: DeleteCareerInput!
    $condition: ModelCareerConditionInput
  ) {
    deleteCareer(input: $input, condition: $condition) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      jobTypeLarge
      jobTypeMiddle
      jobGrade
      joiningDate
      leavingDate
      updatedAt
      createdAt
      careerAdvisorInfoId
    }
  }
`;
export const createAuthentication = /* GraphQL */ `
  mutation CreateAuthentication(
    $input: CreateAuthenticationInput!
    $condition: ModelAuthenticationConditionInput
  ) {
    createAuthentication(input: $input, condition: $condition) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      order
      authType
      authStatus
      documentUrl
      adminName
      adminComment
      updatedAt
      createdAt
      advisorInfoAuthenticationId
    }
  }
`;
export const updateAuthentication = /* GraphQL */ `
  mutation UpdateAuthentication(
    $input: UpdateAuthenticationInput!
    $condition: ModelAuthenticationConditionInput
  ) {
    updateAuthentication(input: $input, condition: $condition) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      order
      authType
      authStatus
      documentUrl
      adminName
      adminComment
      updatedAt
      createdAt
      advisorInfoAuthenticationId
    }
  }
`;
export const deleteAuthentication = /* GraphQL */ `
  mutation DeleteAuthentication(
    $input: DeleteAuthenticationInput!
    $condition: ModelAuthenticationConditionInput
  ) {
    deleteAuthentication(input: $input, condition: $condition) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      order
      authType
      authStatus
      documentUrl
      adminName
      adminComment
      updatedAt
      createdAt
      advisorInfoAuthenticationId
    }
  }
`;
export const createReserve = /* GraphQL */ `
  mutation CreateReserve(
    $input: CreateReserveInput!
    $condition: ModelReserveConditionInput
  ) {
    createReserve(input: $input, condition: $condition) {
      id
      tableType
      startTime
      endTime
      timeDuration
      reservedUserId
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      reservedAdvisorId
      advisor {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      talkRoomStatus
      talkThemeSelectedInputs
      talkThemeDirectInput
      rtcToken
      cancelId
      cancelDateTime
      review
      reviewScore
      advisorMoneyAmount
      advisorSystemCommission
      systemMoneyAmount
      payment {
        id
        paymentId
        endpointType
        sourceId
        customerId
        status
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        processingFeeMoneyAmount
        referenceId
        delayAction
        delayedUntil
        sourceType
        receiptNumber
        receiptUrl
        paymentReserveId
        orderId
        locationId
        reason
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        ttl
        createdAt
        updatedAt
      }
      chat {
        items {
          id
          reserveChatId
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          content
          fileUrl
          postUserId
          isSent
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isVoiceErrorUser
      isVoiceErrorAdvisor
      ttl
      createdAt
      updatedAt
      userReservedUserId
      userReservedAdvisorId
      reservePaymentId
    }
  }
`;
export const updateReserve = /* GraphQL */ `
  mutation UpdateReserve(
    $input: UpdateReserveInput!
    $condition: ModelReserveConditionInput
  ) {
    updateReserve(input: $input, condition: $condition) {
      id
      tableType
      startTime
      endTime
      timeDuration
      reservedUserId
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      reservedAdvisorId
      advisor {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      talkRoomStatus
      talkThemeSelectedInputs
      talkThemeDirectInput
      rtcToken
      cancelId
      cancelDateTime
      review
      reviewScore
      advisorMoneyAmount
      advisorSystemCommission
      systemMoneyAmount
      payment {
        id
        paymentId
        endpointType
        sourceId
        customerId
        status
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        processingFeeMoneyAmount
        referenceId
        delayAction
        delayedUntil
        sourceType
        receiptNumber
        receiptUrl
        paymentReserveId
        orderId
        locationId
        reason
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        ttl
        createdAt
        updatedAt
      }
      chat {
        items {
          id
          reserveChatId
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          content
          fileUrl
          postUserId
          isSent
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isVoiceErrorUser
      isVoiceErrorAdvisor
      ttl
      createdAt
      updatedAt
      userReservedUserId
      userReservedAdvisorId
      reservePaymentId
    }
  }
`;
export const deleteReserve = /* GraphQL */ `
  mutation DeleteReserve(
    $input: DeleteReserveInput!
    $condition: ModelReserveConditionInput
  ) {
    deleteReserve(input: $input, condition: $condition) {
      id
      tableType
      startTime
      endTime
      timeDuration
      reservedUserId
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      reservedAdvisorId
      advisor {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      talkRoomStatus
      talkThemeSelectedInputs
      talkThemeDirectInput
      rtcToken
      cancelId
      cancelDateTime
      review
      reviewScore
      advisorMoneyAmount
      advisorSystemCommission
      systemMoneyAmount
      payment {
        id
        paymentId
        endpointType
        sourceId
        customerId
        status
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        processingFeeMoneyAmount
        referenceId
        delayAction
        delayedUntil
        sourceType
        receiptNumber
        receiptUrl
        paymentReserveId
        orderId
        locationId
        reason
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        ttl
        createdAt
        updatedAt
      }
      chat {
        items {
          id
          reserveChatId
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          content
          fileUrl
          postUserId
          isSent
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isVoiceErrorUser
      isVoiceErrorAdvisor
      ttl
      createdAt
      updatedAt
      userReservedUserId
      userReservedAdvisorId
      reservePaymentId
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      reserveChatId
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      content
      fileUrl
      postUserId
      isSent
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      reserveChatId
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      content
      fileUrl
      postUserId
      isSent
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      reserveChatId
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      content
      fileUrl
      postUserId
      isSent
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      paymentId
      endpointType
      sourceId
      customerId
      status
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      processingFeeMoneyAmount
      referenceId
      delayAction
      delayedUntil
      sourceType
      receiptNumber
      receiptUrl
      paymentReserveId
      orderId
      locationId
      reason
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      paymentId
      endpointType
      sourceId
      customerId
      status
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      processingFeeMoneyAmount
      referenceId
      delayAction
      delayedUntil
      sourceType
      receiptNumber
      receiptUrl
      paymentReserveId
      orderId
      locationId
      reason
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      paymentId
      endpointType
      sourceId
      customerId
      status
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      processingFeeMoneyAmount
      referenceId
      delayAction
      delayedUntil
      sourceType
      receiptNumber
      receiptUrl
      paymentReserveId
      orderId
      locationId
      reason
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentHistory = /* GraphQL */ `
  mutation CreatePaymentHistory(
    $input: CreatePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    createPaymentHistory(input: $input, condition: $condition) {
      paymentId
      endpointType
      status
      idempotencyKey
      sourceId
      customerId
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      delayAction
      delayedUntil
      sourceType
      paymentCreatedAt
      paymentUpdatedAt
      ttl
      createdAt
      updatedAt
      id
    }
  }
`;
export const updatePaymentHistory = /* GraphQL */ `
  mutation UpdatePaymentHistory(
    $input: UpdatePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    updatePaymentHistory(input: $input, condition: $condition) {
      paymentId
      endpointType
      status
      idempotencyKey
      sourceId
      customerId
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      delayAction
      delayedUntil
      sourceType
      paymentCreatedAt
      paymentUpdatedAt
      ttl
      createdAt
      updatedAt
      id
    }
  }
`;
export const deletePaymentHistory = /* GraphQL */ `
  mutation DeletePaymentHistory(
    $input: DeletePaymentHistoryInput!
    $condition: ModelPaymentHistoryConditionInput
  ) {
    deletePaymentHistory(input: $input, condition: $condition) {
      paymentId
      endpointType
      status
      idempotencyKey
      sourceId
      customerId
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      delayAction
      delayedUntil
      sourceType
      paymentCreatedAt
      paymentUpdatedAt
      ttl
      createdAt
      updatedAt
      id
    }
  }
`;
export const createPaymentCard = /* GraphQL */ `
  mutation CreatePaymentCard(
    $input: CreatePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    createPaymentCard(input: $input, condition: $condition) {
      id
      sourceId
      customerId
      referenceId
      cardBrand
      last4
      expMonth
      expYear
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentCard = /* GraphQL */ `
  mutation UpdatePaymentCard(
    $input: UpdatePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    updatePaymentCard(input: $input, condition: $condition) {
      id
      sourceId
      customerId
      referenceId
      cardBrand
      last4
      expMonth
      expYear
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentCard = /* GraphQL */ `
  mutation DeletePaymentCard(
    $input: DeletePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    deletePaymentCard(input: $input, condition: $condition) {
      id
      sourceId
      customerId
      referenceId
      cardBrand
      last4
      expMonth
      expYear
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyNameMaster = /* GraphQL */ `
  mutation CreateCompanyNameMaster(
    $input: CreateCompanyNameMasterInput!
    $condition: ModelCompanyNameMasterConditionInput
  ) {
    createCompanyNameMaster(input: $input, condition: $condition) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      sequenceNumber
      corporateNumber
      process
      correct
      updateDate
      changeDate
      name
      nameImageId
      kind
      prefectureName
      cityName
      streetNumber
      addressImageId
      prefectureCode
      cityCode
      postCode
      addressOutside
      addressOutsideImageId
      closeDate
      closeCause
      successorCorporateNumber
      changeCause
      assignmentDate
      latest
      enName
      enPrefectureName
      enCityName
      enAddressOutside
      furigana
      hihyoji
      registeredCount
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyNameMaster = /* GraphQL */ `
  mutation UpdateCompanyNameMaster(
    $input: UpdateCompanyNameMasterInput!
    $condition: ModelCompanyNameMasterConditionInput
  ) {
    updateCompanyNameMaster(input: $input, condition: $condition) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      sequenceNumber
      corporateNumber
      process
      correct
      updateDate
      changeDate
      name
      nameImageId
      kind
      prefectureName
      cityName
      streetNumber
      addressImageId
      prefectureCode
      cityCode
      postCode
      addressOutside
      addressOutsideImageId
      closeDate
      closeCause
      successorCorporateNumber
      changeCause
      assignmentDate
      latest
      enName
      enPrefectureName
      enCityName
      enAddressOutside
      furigana
      hihyoji
      registeredCount
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyNameMaster = /* GraphQL */ `
  mutation DeleteCompanyNameMaster(
    $input: DeleteCompanyNameMasterInput!
    $condition: ModelCompanyNameMasterConditionInput
  ) {
    deleteCompanyNameMaster(input: $input, condition: $condition) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      sequenceNumber
      corporateNumber
      process
      correct
      updateDate
      changeDate
      name
      nameImageId
      kind
      prefectureName
      cityName
      streetNumber
      addressImageId
      prefectureCode
      cityCode
      postCode
      addressOutside
      addressOutsideImageId
      closeDate
      closeCause
      successorCorporateNumber
      changeCause
      assignmentDate
      latest
      enName
      enPrefectureName
      enCityName
      enAddressOutside
      furigana
      hihyoji
      registeredCount
      version
      createdAt
      updatedAt
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createBankMaster = /* GraphQL */ `
  mutation CreateBankMaster(
    $input: CreateBankMasterInput!
    $condition: ModelBankMasterConditionInput
  ) {
    createBankMaster(input: $input, condition: $condition) {
      id
      code
      name
      halfWidthKana
      fullWidthKana
      hiragana
      businessTypeCode
      businessType
      createdAt
      updatedAt
    }
  }
`;
export const updateBankMaster = /* GraphQL */ `
  mutation UpdateBankMaster(
    $input: UpdateBankMasterInput!
    $condition: ModelBankMasterConditionInput
  ) {
    updateBankMaster(input: $input, condition: $condition) {
      id
      code
      name
      halfWidthKana
      fullWidthKana
      hiragana
      businessTypeCode
      businessType
      createdAt
      updatedAt
    }
  }
`;
export const deleteBankMaster = /* GraphQL */ `
  mutation DeleteBankMaster(
    $input: DeleteBankMasterInput!
    $condition: ModelBankMasterConditionInput
  ) {
    deleteBankMaster(input: $input, condition: $condition) {
      id
      code
      name
      halfWidthKana
      fullWidthKana
      hiragana
      businessTypeCode
      businessType
      createdAt
      updatedAt
    }
  }
`;
export const createDepositServiceSetting = /* GraphQL */ `
  mutation CreateDepositServiceSetting(
    $input: CreateDepositServiceSettingInput!
    $condition: ModelDepositServiceSettingConditionInput
  ) {
    createDepositServiceSetting(input: $input, condition: $condition) {
      id
      depositMinAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      bankTransferRefundCommission
      oneMonthMaxAmount
      shopId
      shopPass
      createdAt
      updatedAt
    }
  }
`;
export const updateDepositServiceSetting = /* GraphQL */ `
  mutation UpdateDepositServiceSetting(
    $input: UpdateDepositServiceSettingInput!
    $condition: ModelDepositServiceSettingConditionInput
  ) {
    updateDepositServiceSetting(input: $input, condition: $condition) {
      id
      depositMinAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      bankTransferRefundCommission
      oneMonthMaxAmount
      shopId
      shopPass
      createdAt
      updatedAt
    }
  }
`;
export const deleteDepositServiceSetting = /* GraphQL */ `
  mutation DeleteDepositServiceSetting(
    $input: DeleteDepositServiceSettingInput!
    $condition: ModelDepositServiceSettingConditionInput
  ) {
    deleteDepositServiceSetting(input: $input, condition: $condition) {
      id
      depositMinAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      bankTransferRefundCommission
      oneMonthMaxAmount
      shopId
      shopPass
      createdAt
      updatedAt
    }
  }
`;
export const createHolidayMaster = /* GraphQL */ `
  mutation CreateHolidayMaster(
    $input: CreateHolidayMasterInput!
    $condition: ModelHolidayMasterConditionInput
  ) {
    createHolidayMaster(input: $input, condition: $condition) {
      id
      holidayDate
      holidayName
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateHolidayMaster = /* GraphQL */ `
  mutation UpdateHolidayMaster(
    $input: UpdateHolidayMasterInput!
    $condition: ModelHolidayMasterConditionInput
  ) {
    updateHolidayMaster(input: $input, condition: $condition) {
      id
      holidayDate
      holidayName
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteHolidayMaster = /* GraphQL */ `
  mutation DeleteHolidayMaster(
    $input: DeleteHolidayMasterInput!
    $condition: ModelHolidayMasterConditionInput
  ) {
    deleteHolidayMaster(input: $input, condition: $condition) {
      id
      holidayDate
      holidayName
      note
      createdAt
      updatedAt
    }
  }
`;
export const createAdvisorFinancialInfo = /* GraphQL */ `
  mutation CreateAdvisorFinancialInfo(
    $input: CreateAdvisorFinancialInfoInput!
    $condition: ModelAdvisorFinancialInfoConditionInput
  ) {
    createAdvisorFinancialInfo(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      bankAccountInfoId
      lastDepositStatus
      lastDepositDate
      cumulativeAmount
      presentBalance
      systemCommission
      createdAt
      updatedAt
      advisorFinancialInfoAdvisorId
    }
  }
`;
export const updateAdvisorFinancialInfo = /* GraphQL */ `
  mutation UpdateAdvisorFinancialInfo(
    $input: UpdateAdvisorFinancialInfoInput!
    $condition: ModelAdvisorFinancialInfoConditionInput
  ) {
    updateAdvisorFinancialInfo(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      bankAccountInfoId
      lastDepositStatus
      lastDepositDate
      cumulativeAmount
      presentBalance
      systemCommission
      createdAt
      updatedAt
      advisorFinancialInfoAdvisorId
    }
  }
`;
export const deleteAdvisorFinancialInfo = /* GraphQL */ `
  mutation DeleteAdvisorFinancialInfo(
    $input: DeleteAdvisorFinancialInfoInput!
    $condition: ModelAdvisorFinancialInfoConditionInput
  ) {
    deleteAdvisorFinancialInfo(input: $input, condition: $condition) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      bankAccountInfoId
      lastDepositStatus
      lastDepositDate
      cumulativeAmount
      presentBalance
      systemCommission
      createdAt
      updatedAt
      advisorFinancialInfoAdvisorId
    }
  }
`;
export const createDepositHistory = /* GraphQL */ `
  mutation CreateDepositHistory(
    $input: CreateDepositHistoryInput!
    $condition: ModelDepositHistoryConditionInput
  ) {
    createDepositHistory(input: $input, condition: $condition) {
      id
      advisorId
      bankAccountInfoId
      depositId
      depositStatus
      depositAmount
      depositBankFee
      depositStatusUpdateInfo {
        depositStatus
        statusUpdateTime
      }
      depositExpectedDate
      closingDate
      errorResultDetail
      errorCommission
      bankName
      branchCode
      accountTypeName
      accountNumberLast3
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateDepositHistory = /* GraphQL */ `
  mutation UpdateDepositHistory(
    $input: UpdateDepositHistoryInput!
    $condition: ModelDepositHistoryConditionInput
  ) {
    updateDepositHistory(input: $input, condition: $condition) {
      id
      advisorId
      bankAccountInfoId
      depositId
      depositStatus
      depositAmount
      depositBankFee
      depositStatusUpdateInfo {
        depositStatus
        statusUpdateTime
      }
      depositExpectedDate
      closingDate
      errorResultDetail
      errorCommission
      bankName
      branchCode
      accountTypeName
      accountNumberLast3
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteDepositHistory = /* GraphQL */ `
  mutation DeleteDepositHistory(
    $input: DeleteDepositHistoryInput!
    $condition: ModelDepositHistoryConditionInput
  ) {
    deleteDepositHistory(input: $input, condition: $condition) {
      id
      advisorId
      bankAccountInfoId
      depositId
      depositStatus
      depositAmount
      depositBankFee
      depositStatusUpdateInfo {
        depositStatus
        statusUpdateTime
      }
      depositExpectedDate
      closingDate
      errorResultDetail
      errorCommission
      bankName
      branchCode
      accountTypeName
      accountNumberLast3
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createDeposit = /* GraphQL */ `
  mutation CreateDeposit(
    $input: CreateDepositInput!
    $condition: ModelDepositConditionInput
  ) {
    createDeposit(input: $input, condition: $condition) {
      id
      depositMinAmount
      processCategory
      advisorId
      bankId
      depositId
      accountNumber
      accountType
      accountName
      bankCode
      bankName
      branchCode
      branchName
      lastDepositStatus
      presentBalance
      depositApplicableAmount
      depositAmount
      depositBankFee
      depositExpectedDate
      closingDate
      errorDetail
      beginsWithHiraganaBankName
      depositHistoryView {
        depositId
        depositHistoryId
        depositStatus
        applicationDateTime
        depositApplicationAmountTotal
        depositBankFee
        depositAmount
        depositExpectedDate
        bankName
        branchCode
        accountTypeName
        accountNumberLast3
        closingDate
        errorResultDetail
        errorCommission
      }
      bankInfos {
        bankCode
        bankName
        bankHiragana
      }
      bigBanks {
        bankCode
        bankName
        bankHiragana
      }
      oneMonthMaxAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      lastEvaluatedKey
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateDeposit = /* GraphQL */ `
  mutation UpdateDeposit(
    $input: UpdateDepositInput!
    $condition: ModelDepositConditionInput
  ) {
    updateDeposit(input: $input, condition: $condition) {
      id
      depositMinAmount
      processCategory
      advisorId
      bankId
      depositId
      accountNumber
      accountType
      accountName
      bankCode
      bankName
      branchCode
      branchName
      lastDepositStatus
      presentBalance
      depositApplicableAmount
      depositAmount
      depositBankFee
      depositExpectedDate
      closingDate
      errorDetail
      beginsWithHiraganaBankName
      depositHistoryView {
        depositId
        depositHistoryId
        depositStatus
        applicationDateTime
        depositApplicationAmountTotal
        depositBankFee
        depositAmount
        depositExpectedDate
        bankName
        branchCode
        accountTypeName
        accountNumberLast3
        closingDate
        errorResultDetail
        errorCommission
      }
      bankInfos {
        bankCode
        bankName
        bankHiragana
      }
      bigBanks {
        bankCode
        bankName
        bankHiragana
      }
      oneMonthMaxAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      lastEvaluatedKey
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeposit = /* GraphQL */ `
  mutation DeleteDeposit(
    $input: DeleteDepositInput!
    $condition: ModelDepositConditionInput
  ) {
    deleteDeposit(input: $input, condition: $condition) {
      id
      depositMinAmount
      processCategory
      advisorId
      bankId
      depositId
      accountNumber
      accountType
      accountName
      bankCode
      bankName
      branchCode
      branchName
      lastDepositStatus
      presentBalance
      depositApplicableAmount
      depositAmount
      depositBankFee
      depositExpectedDate
      closingDate
      errorDetail
      beginsWithHiraganaBankName
      depositHistoryView {
        depositId
        depositHistoryId
        depositStatus
        applicationDateTime
        depositApplicationAmountTotal
        depositBankFee
        depositAmount
        depositExpectedDate
        bankName
        branchCode
        accountTypeName
        accountNumberLast3
        closingDate
        errorResultDetail
        errorCommission
      }
      bankInfos {
        bankCode
        bankName
        bankHiragana
      }
      bigBanks {
        bankCode
        bankName
        bankHiragana
      }
      oneMonthMaxAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      lastEvaluatedKey
      note
      createdAt
      updatedAt
    }
  }
`;
export const createCategoryMaster = /* GraphQL */ `
  mutation CreateCategoryMaster(
    $input: CreateCategoryMasterInput!
    $condition: ModelCategoryMasterConditionInput
  ) {
    createCategoryMaster(input: $input, condition: $condition) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const updateCategoryMaster = /* GraphQL */ `
  mutation UpdateCategoryMaster(
    $input: UpdateCategoryMasterInput!
    $condition: ModelCategoryMasterConditionInput
  ) {
    updateCategoryMaster(input: $input, condition: $condition) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategoryMaster = /* GraphQL */ `
  mutation DeleteCategoryMaster(
    $input: DeleteCategoryMasterInput!
    $condition: ModelCategoryMasterConditionInput
  ) {
    deleteCategoryMaster(input: $input, condition: $condition) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    createFaq(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    updateFaq(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    deleteFaq(input: $input, condition: $condition) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const createNotificationHistory = /* GraphQL */ `
  mutation CreateNotificationHistory(
    $input: CreateNotificationHistoryInput!
    $condition: ModelNotificationHistoryConditionInput
  ) {
    createNotificationHistory(input: $input, condition: $condition) {
      id
      tableName
      tableId
      userId
      senderId
      senderProfileUrl
      token
      title
      body
      badge
      category
      isConfirm
      imageUrl
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationHistory = /* GraphQL */ `
  mutation UpdateNotificationHistory(
    $input: UpdateNotificationHistoryInput!
    $condition: ModelNotificationHistoryConditionInput
  ) {
    updateNotificationHistory(input: $input, condition: $condition) {
      id
      tableName
      tableId
      userId
      senderId
      senderProfileUrl
      token
      title
      body
      badge
      category
      isConfirm
      imageUrl
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationHistory = /* GraphQL */ `
  mutation DeleteNotificationHistory(
    $input: DeleteNotificationHistoryInput!
    $condition: ModelNotificationHistoryConditionInput
  ) {
    deleteNotificationHistory(input: $input, condition: $condition) {
      id
      tableName
      tableId
      userId
      senderId
      senderProfileUrl
      token
      title
      body
      badge
      category
      isConfirm
      imageUrl
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createBankAccountInfo = /* GraphQL */ `
  mutation CreateBankAccountInfo(
    $input: CreateBankAccountInfoInput!
    $condition: ModelBankAccountInfoConditionInput
  ) {
    createBankAccountInfo(input: $input, condition: $condition) {
      id
      advisorId
      bankCode
      bankName
      branchCode
      accountType
      accountNumber
      accountName
      accountStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateBankAccountInfo = /* GraphQL */ `
  mutation UpdateBankAccountInfo(
    $input: UpdateBankAccountInfoInput!
    $condition: ModelBankAccountInfoConditionInput
  ) {
    updateBankAccountInfo(input: $input, condition: $condition) {
      id
      advisorId
      bankCode
      bankName
      branchCode
      accountType
      accountNumber
      accountName
      accountStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteBankAccountInfo = /* GraphQL */ `
  mutation DeleteBankAccountInfo(
    $input: DeleteBankAccountInfoInput!
    $condition: ModelBankAccountInfoConditionInput
  ) {
    deleteBankAccountInfo(input: $input, condition: $condition) {
      id
      advisorId
      bankCode
      bankName
      branchCode
      accountType
      accountNumber
      accountName
      accountStatus
      createdAt
      updatedAt
    }
  }
`;
export const createJobType = /* GraphQL */ `
  mutation CreateJobType(
    $input: CreateJobTypeInput!
    $condition: ModelJobTypeConditionInput
  ) {
    createJobType(input: $input, condition: $condition) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      jobTypeLarge
      jobTypeMiddle
      jobTypeLargeDescription
      jobTypeMiddleDescription
      jobTypeMiddleCount
      createdAt
      updatedAt
    }
  }
`;
export const updateJobType = /* GraphQL */ `
  mutation UpdateJobType(
    $input: UpdateJobTypeInput!
    $condition: ModelJobTypeConditionInput
  ) {
    updateJobType(input: $input, condition: $condition) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      jobTypeLarge
      jobTypeMiddle
      jobTypeLargeDescription
      jobTypeMiddleDescription
      jobTypeMiddleCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobType = /* GraphQL */ `
  mutation DeleteJobType(
    $input: DeleteJobTypeInput!
    $condition: ModelJobTypeConditionInput
  ) {
    deleteJobType(input: $input, condition: $condition) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      jobTypeLarge
      jobTypeMiddle
      jobTypeLargeDescription
      jobTypeMiddleDescription
      jobTypeMiddleCount
      createdAt
      updatedAt
    }
  }
`;
export const createBankMasterHistory = /* GraphQL */ `
  mutation CreateBankMasterHistory(
    $input: CreateBankMasterHistoryInput!
    $condition: ModelBankMasterHistoryConditionInput
  ) {
    createBankMasterHistory(input: $input, condition: $condition) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const updateBankMasterHistory = /* GraphQL */ `
  mutation UpdateBankMasterHistory(
    $input: UpdateBankMasterHistoryInput!
    $condition: ModelBankMasterHistoryConditionInput
  ) {
    updateBankMasterHistory(input: $input, condition: $condition) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const deleteBankMasterHistory = /* GraphQL */ `
  mutation DeleteBankMasterHistory(
    $input: DeleteBankMasterHistoryInput!
    $condition: ModelBankMasterHistoryConditionInput
  ) {
    deleteBankMasterHistory(input: $input, condition: $condition) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const createProcess = /* GraphQL */ `
  mutation CreateProcess(
    $input: CreateProcessInput!
    $condition: ModelProcessConditionInput
  ) {
    createProcess(input: $input, condition: $condition) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateProcess = /* GraphQL */ `
  mutation UpdateProcess(
    $input: UpdateProcessInput!
    $condition: ModelProcessConditionInput
  ) {
    updateProcess(input: $input, condition: $condition) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteProcess = /* GraphQL */ `
  mutation DeleteProcess(
    $input: DeleteProcessInput!
    $condition: ModelProcessConditionInput
  ) {
    deleteProcess(input: $input, condition: $condition) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const createEventBanner = /* GraphQL */ `
  mutation CreateEventBanner(
    $input: CreateEventBannerInput!
    $condition: ModelEventBannerConditionInput
  ) {
    createEventBanner(input: $input, condition: $condition) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const updateEventBanner = /* GraphQL */ `
  mutation UpdateEventBanner(
    $input: UpdateEventBannerInput!
    $condition: ModelEventBannerConditionInput
  ) {
    updateEventBanner(input: $input, condition: $condition) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventBanner = /* GraphQL */ `
  mutation DeleteEventBanner(
    $input: DeleteEventBannerInput!
    $condition: ModelEventBannerConditionInput
  ) {
    deleteEventBanner(input: $input, condition: $condition) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const createPopularityAdvisor = /* GraphQL */ `
  mutation CreatePopularityAdvisor(
    $input: CreatePopularityAdvisorInput!
    $condition: ModelPopularityAdvisorConditionInput
  ) {
    createPopularityAdvisor(input: $input, condition: $condition) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      popularityAdvisorAdvisorIdId
    }
  }
`;
export const updatePopularityAdvisor = /* GraphQL */ `
  mutation UpdatePopularityAdvisor(
    $input: UpdatePopularityAdvisorInput!
    $condition: ModelPopularityAdvisorConditionInput
  ) {
    updatePopularityAdvisor(input: $input, condition: $condition) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      popularityAdvisorAdvisorIdId
    }
  }
`;
export const deletePopularityAdvisor = /* GraphQL */ `
  mutation DeletePopularityAdvisor(
    $input: DeletePopularityAdvisorInput!
    $condition: ModelPopularityAdvisorConditionInput
  ) {
    deletePopularityAdvisor(input: $input, condition: $condition) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      popularityAdvisorAdvisorIdId
    }
  }
`;
export const createRecommendAdvisor = /* GraphQL */ `
  mutation CreateRecommendAdvisor(
    $input: CreateRecommendAdvisorInput!
    $condition: ModelRecommendAdvisorConditionInput
  ) {
    createRecommendAdvisor(input: $input, condition: $condition) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      recommendAdvisorAdvisorIdId
    }
  }
`;
export const updateRecommendAdvisor = /* GraphQL */ `
  mutation UpdateRecommendAdvisor(
    $input: UpdateRecommendAdvisorInput!
    $condition: ModelRecommendAdvisorConditionInput
  ) {
    updateRecommendAdvisor(input: $input, condition: $condition) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      recommendAdvisorAdvisorIdId
    }
  }
`;
export const deleteRecommendAdvisor = /* GraphQL */ `
  mutation DeleteRecommendAdvisor(
    $input: DeleteRecommendAdvisorInput!
    $condition: ModelRecommendAdvisorConditionInput
  ) {
    deleteRecommendAdvisor(input: $input, condition: $condition) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      recommendAdvisorAdvisorIdId
    }
  }
`;
export const createCompanyNameMasterHistory = /* GraphQL */ `
  mutation CreateCompanyNameMasterHistory(
    $input: CreateCompanyNameMasterHistoryInput!
    $condition: ModelCompanyNameMasterHistoryConditionInput
  ) {
    createCompanyNameMasterHistory(input: $input, condition: $condition) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyNameMasterHistory = /* GraphQL */ `
  mutation UpdateCompanyNameMasterHistory(
    $input: UpdateCompanyNameMasterHistoryInput!
    $condition: ModelCompanyNameMasterHistoryConditionInput
  ) {
    updateCompanyNameMasterHistory(input: $input, condition: $condition) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyNameMasterHistory = /* GraphQL */ `
  mutation DeleteCompanyNameMasterHistory(
    $input: DeleteCompanyNameMasterHistoryInput!
    $condition: ModelCompanyNameMasterHistoryConditionInput
  ) {
    deleteCompanyNameMasterHistory(input: $input, condition: $condition) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const createStatusCodeMaster = /* GraphQL */ `
  mutation CreateStatusCodeMaster(
    $input: CreateStatusCodeMasterInput!
    $condition: ModelStatusCodeMasterConditionInput
  ) {
    createStatusCodeMaster(input: $input, condition: $condition) {
      id
      service
      statusCode
      statusDetail
      description
      skServiceStatusCode
      createdAt
      updatedAt
    }
  }
`;
export const updateStatusCodeMaster = /* GraphQL */ `
  mutation UpdateStatusCodeMaster(
    $input: UpdateStatusCodeMasterInput!
    $condition: ModelStatusCodeMasterConditionInput
  ) {
    updateStatusCodeMaster(input: $input, condition: $condition) {
      id
      service
      statusCode
      statusDetail
      description
      skServiceStatusCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatusCodeMaster = /* GraphQL */ `
  mutation DeleteStatusCodeMaster(
    $input: DeleteStatusCodeMasterInput!
    $condition: ModelStatusCodeMasterConditionInput
  ) {
    deleteStatusCodeMaster(input: $input, condition: $condition) {
      id
      service
      statusCode
      statusDetail
      description
      skServiceStatusCode
      createdAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      urlCode
      price
      expirationDate
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      isRead
      isSend
      isDisable
      stepFunctionId
      couponType
      contents
      promotionHistory {
        id
        coupon {
          id
          urlCode
          price
          expirationDate
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          isRead
          isSend
          isDisable
          stepFunctionId
          couponType
          contents
          promotionHistory {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          createdAt
          updatedAt
          couponPromotionId
          couponPromotionHistoryId
        }
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        contents
        createdAt
        updatedAt
        userPromotionHistoryId
        promotionPromotionHistoryId
        promotionHistoryCouponId
      }
      createdAt
      updatedAt
      couponPromotionId
      couponPromotionHistoryId
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      urlCode
      price
      expirationDate
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      isRead
      isSend
      isDisable
      stepFunctionId
      couponType
      contents
      promotionHistory {
        id
        coupon {
          id
          urlCode
          price
          expirationDate
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          isRead
          isSend
          isDisable
          stepFunctionId
          couponType
          contents
          promotionHistory {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          createdAt
          updatedAt
          couponPromotionId
          couponPromotionHistoryId
        }
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        contents
        createdAt
        updatedAt
        userPromotionHistoryId
        promotionPromotionHistoryId
        promotionHistoryCouponId
      }
      createdAt
      updatedAt
      couponPromotionId
      couponPromotionHistoryId
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      urlCode
      price
      expirationDate
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      isRead
      isSend
      isDisable
      stepFunctionId
      couponType
      contents
      promotionHistory {
        id
        coupon {
          id
          urlCode
          price
          expirationDate
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          isRead
          isSend
          isDisable
          stepFunctionId
          couponType
          contents
          promotionHistory {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          createdAt
          updatedAt
          couponPromotionId
          couponPromotionHistoryId
        }
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        contents
        createdAt
        updatedAt
        userPromotionHistoryId
        promotionPromotionHistoryId
        promotionHistoryCouponId
      }
      createdAt
      updatedAt
      couponPromotionId
      couponPromotionHistoryId
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion(
    $input: CreatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    createPromotion(input: $input, condition: $condition) {
      id
      title
      description
      target
      messageOption
      option
      fixedCount
      isAuto
      status
      couponType
      startTime
      endTime
      contents
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion(
    $input: UpdatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    updatePromotion(input: $input, condition: $condition) {
      id
      title
      description
      target
      messageOption
      option
      fixedCount
      isAuto
      status
      couponType
      startTime
      endTime
      contents
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion(
    $input: DeletePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    deletePromotion(input: $input, condition: $condition) {
      id
      title
      description
      target
      messageOption
      option
      fixedCount
      isAuto
      status
      couponType
      startTime
      endTime
      contents
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPromotionHistory = /* GraphQL */ `
  mutation CreatePromotionHistory(
    $input: CreatePromotionHistoryInput!
    $condition: ModelPromotionHistoryConditionInput
  ) {
    createPromotionHistory(input: $input, condition: $condition) {
      id
      coupon {
        id
        urlCode
        price
        expirationDate
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isRead
        isSend
        isDisable
        stepFunctionId
        couponType
        contents
        promotionHistory {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        createdAt
        updatedAt
        couponPromotionId
        couponPromotionHistoryId
      }
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      contents
      createdAt
      updatedAt
      userPromotionHistoryId
      promotionPromotionHistoryId
      promotionHistoryCouponId
    }
  }
`;
export const updatePromotionHistory = /* GraphQL */ `
  mutation UpdatePromotionHistory(
    $input: UpdatePromotionHistoryInput!
    $condition: ModelPromotionHistoryConditionInput
  ) {
    updatePromotionHistory(input: $input, condition: $condition) {
      id
      coupon {
        id
        urlCode
        price
        expirationDate
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isRead
        isSend
        isDisable
        stepFunctionId
        couponType
        contents
        promotionHistory {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        createdAt
        updatedAt
        couponPromotionId
        couponPromotionHistoryId
      }
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      contents
      createdAt
      updatedAt
      userPromotionHistoryId
      promotionPromotionHistoryId
      promotionHistoryCouponId
    }
  }
`;
export const deletePromotionHistory = /* GraphQL */ `
  mutation DeletePromotionHistory(
    $input: DeletePromotionHistoryInput!
    $condition: ModelPromotionHistoryConditionInput
  ) {
    deletePromotionHistory(input: $input, condition: $condition) {
      id
      coupon {
        id
        urlCode
        price
        expirationDate
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isRead
        isSend
        isDisable
        stepFunctionId
        couponType
        contents
        promotionHistory {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        createdAt
        updatedAt
        couponPromotionId
        couponPromotionHistoryId
      }
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      contents
      createdAt
      updatedAt
      userPromotionHistoryId
      promotionPromotionHistoryId
      promotionHistoryCouponId
    }
  }
`;
