import React from "react";
import "./App.css";
import "./assets/styles/step.css";
import { routes } from "./config/route";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import TopButton from "./components/TopButton/TopButton";
import "./assets/styles/index.css";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import Layout from "./Layout";

Amplify.configure(awsExports);
export const App: React.VFC = () => {
  return (
    <div className="App">
      <Layout>
        <BrowserRouter>
          <Routes>
            {routes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Routes>
        </BrowserRouter>
        <TopButton></TopButton>
      </Layout>
    </div>
  );
};

export default App;
