import React from "react";

export default function Example() {
  return (
    <>
      <div
        className="bottom-auto top-0 left-0 right-0 w-full border-0 absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style={{ transform: "translateZ(0)" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-blueGray-800 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div className="container mx-auto px-4 pt-24 lg:pt-24 pb-20 text-center">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full lg:w-8/12 px-4">
            <h2 className="text-2xl font-semibold text-blueGray-200">活用例</h2>
          </div>
        </div>
        <div className="text-center">
          <p className="text-3xl leading-relaxed mt-4 mb-4 font-semibold text-blueGray-200">
            就職・転職
          </p>
        </div>
        <div className="flex flex-wrap mt-12 justify-center">
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className="fas fa-medal text-xl"></i>
            </div>
            <h6 className="text-2xl mt-5 font-semibold text-blueGray-200">
              会社の社員満足度
            </h6>
            <p className="mt-2 mb-4 font-semibold text-blueGray-200">
              会社の社員の仕事満足度が気になる
            </p>
          </div>
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className="fas fa-poll text-xl"></i>
            </div>
            <h5 className="text-2xl mt-5 font-semibold text-blueGray-200">
              会社の福祉
            </h5>
            <p className="mt-2 mb-4 font-semibold text-blueGray-200">
              内定を受けた会社の入社後の給与やボーナスは想定通りか気になる
            </p>
          </div>
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className="fas fa-lightbulb text-xl"></i>
            </div>
            <h5 className="text-2xl mt-5 font-semibold text-blueGray-200">
              雇用情報
            </h5>
            <p className="mt-2 mb-4 font-semibold text-blueGray-200">
              雇用情報OKと書いてあるけど、自分が勤まるか不安である
            </p>
          </div>
        </div>
        <div className="text-center">
          <p className="text-3xl leading-relaxed mt-4 mb-4 font-semibold text-white">
            スキルアップ
          </p>
        </div>
        <div className="flex flex-wrap mt-12 justify-center">
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className="fa-sharp fa-solid fa-share-nodes text-xl"></i>
            </div>
            <h6 className="text-2xl mt-5 font-semibold text-blueGray-200">
              将来
            </h6>
            <p className="mt-2 mb-4 font-semibold text-blueGray-200">
              コンサルティングを目指しているが、どのようにキャリアアップすれば良いか気になる
            </p>
          </div>
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className="fa-sharp fa-solid fa-briefcase text-xl"></i>
            </div>
            <h5 className="text-2xl mt-5 font-semibold text-blueGray-200">
              DX化
            </h5>
            <p className="mt-2 mb-4 font-semibold text-blueGray-200">
              飲食業界をDX化したいが、飲食業界の課題や仕組みが分からない
            </p>
          </div>
          <div className="w-full lg:w-3/12 px-4 text-center">
            <div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
              <i className="fa-sharp fa-solid fa-bookmark text-xl"></i>
            </div>
            <h5 className="text-2xl mt-5 font-semibold text-blueGray-200">
              BtoCサービス
            </h5>
            <p className="mt-2 mb-4 font-semibold text-blueGray-200">
              採用向けBtoCサービスを構想中だが、現職の求人担当者の意見を聞きたい
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
