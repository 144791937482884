import list from "../../assets/img/list/list.svg";
import React from "react";

export default function Object() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                <img src={list} alt="" />
              </div>
              <h6 className="text-2xl font-semibold">就職・転職</h6>
              <p className="mt-2 mb-4 font-semibold text-blueGray-500 text-md lg:text-xl">
                ユーザー様の就職・転職のお悩みをアドバイザー様と共に解決できます。
              </p>
            </div>
          </div>
        </div>

        <div className="w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                <img src={list} alt="" />
              </div>
              <h6 className="text-2xl font-semibold">スキルアップ</h6>
              <p className="mt-2 mb-4 font-semibold text-blueGray-500 text-md lg:text-xl">
                スキルアップするための知識やアドバイスをいつでも受け取れます。
              </p>
            </div>
          </div>
        </div>

        <div className="pt-6 w-full md:w-4/12 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                <img src={list} alt="" />
              </div>
              <h6 className="text-2xl font-semibold">マッチング</h6>
              <p className="mt-2 mb-4 font-semibold text-blueGray-500 text-md lg:text-xl">
                アドバイザー様が設定した時間を選んで気軽に会話できます！
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
