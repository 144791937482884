import React, { useEffect, useState } from "react";
import appstore from "../../assets/img/main/App_Store_Badge_JP.png";
import googleplay from "../../assets/img/main/google-play-badge.png";

const MobileDownload = (props: any) => {
  let mobile: string;

  const [deviceIOS, setDeviceIOS] = useState(false);
  const [deviceMobile, setDeviceMobile] = useState(false);

  useEffect(() => {
    mobile = window.navigator.userAgent;
    if (mobile.match(/iPhone/) || mobile.match(/IPad/) || mobile.match(/Mac/)) {
      setDeviceIOS(true);
    }
    if (
      mobile.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/
      )
    ) {
      setDeviceMobile(true);
    }
  });
  return (
    <>
      {deviceMobile ? (
        <div className="w-full max-w-xs fixed bottom-5 z-50 outline-none">
          <div
            id="toast-undo"
            className="flex items-center p-2 text-gray-500 bg-white rounded-lg shadow"
            role="alert"
          >
            <div className="text-sm font-semibold ml-2 mr-3">
              ダウンロードはこちら
            </div>
            <div className="flex items-center ml-auto space-x-2">
              {deviceIOS ? (
                <a
                  href="https://apps.apple.com/jp/app/dangotalk/id6444250993"
                  className="inline-block"
                >
                  <img
                    alt="Appstore でダウンロード"
                    src={appstore}
                    className="max-w-100-px"
                  />
                </a>
              ) : (
                <a
                  href="https://play.google.com/store/apps/details?id=com.dangotalk.app&hl=ja&gl=US"
                  className="inline-block"
                >
                  <img
                    alt="Google Play で手に入れよう"
                    src={googleplay}
                    className="max-w-100-px"
                  />
                </a>
              )}
              <button
                type="button"
                className="bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                data-dismiss-target="#toast-undo"
                aria-label="Close"
                onClick={props.showSnackbar}
              >
                <span className="sr-only">Close</span>
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MobileDownload;
