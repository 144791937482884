import React from "react";
import {
  MicrophoneIcon,
  LinkIcon,
  CircleStackIcon,
  FaceSmileIcon,
} from "@heroicons/react/24/outline";

export default function UserMerit() {
  const features = [
    {
      name: "簡単",
      description: "誰でも楽に会員登録して簡単な使い方で気軽にご利用できます。",
      icon: FaceSmileIcon,
    },
    {
      name: "知識習得",
      description:
        "専門的な知識や情報を持っているアドバイザーとマッチングして情報を貰えます。",
      icon: LinkIcon,
    },
    {
      name: "自由",
      description:
        "いつでもどこでも予約された時間にアドバイザーとコミュニケーションができます。",
      icon: MicrophoneIcon,
    },
    {
      name: "低コスト",
      description: "少ない費用で有益な情報を得られます。",
      icon: CircleStackIcon,
    },
  ];
  return (
    <>
      <div className="bg-white pb-24 sm:pb-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-lg md:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              ユーザー様メリット
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl text-start">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-10 md:pl-16">
                  <dt className="text-lg md:text-2xl font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-7 w-7 md:h-10 md:w-10 items-center justify-center rounded-lg bg-lightBlue-500">
                      <feature.icon
                        className="h-4 w-4 md:h-6 md:w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 text-sm md:text-base leading-7 font-semibold text-blueGray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
