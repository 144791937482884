import { API } from "aws-amplify";

export const getQuery = async (query: string, id: string | any) => {
  const params = {
    id: id,
  };
  try {
    return await API.graphql({
      query: query,
      variables: params,
      authMode: "API_KEY",
    });
  } catch (e) {
    console.log(e);
  }
};
