import React from "react";
import { Link } from "react-router-dom";
import list from "../../assets/img/list/list.svg";

export default function Navbar(props: any) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="logo flex text-blueGray-600 text-2xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
              to="/"
            >
              <img src={list} alt="" width={50} className="mr-3" />
              <span className="mx-auto my-auto">DanGoTalk</span>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-blueGray-600 fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="nav flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center list-item">
                <Link
                  className="text-gray-800 md:text-blueGray-600 hover:text-blueGray-300 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-bold"
                  to="/notice"
                >
                  <span className="inline-block ml-2">お知らせ</span>
                </Link>
              </li>
              <li className="flex items-center list-item">
                <Link
                  className="text-gray-800 md:text-blueGray-600 hover:text-blueGray-300 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-bold"
                  to="/faq"
                >
                  <span className="inline-block ml-2">よくあるご質問</span>
                </Link>
              </li>
              <li className="flex items-center list-item">
                <Link
                  className="text-gray-800 md:text-blueGray-600 hover:text-blueGray-300 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-bold"
                  to="/step"
                >
                  <span className="inline-block ml-2">ご利用の流れ</span>
                </Link>
              </li>
              <li className="flex items-center list-item">
                <Link
                  className="text-gray-800 md:text-blueGray-600 hover:text-blueGray-300 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-bold"
                  to="/contact"
                >
                  <span className="inline-block ml-2">お問い合わせ</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
