import {
  Fragment,
  type FunctionComponent,
  type PropsWithChildren,
} from "react";

const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Fragment>{children}</Fragment>
);

export default Layout;
