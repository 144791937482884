/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      fcmToken
      platformDeviceId
      nickname
      familyName
      firstName
      familyNameKana
      firstNameKana
      birthdate
      countryCode
      phoneNumber
      profileUrl
      timeZone
      squareCustomerId
      withdraw
      reservedUser {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      reservedAdvisor {
        items {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        nextToken
      }
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      userAdvisorId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdvisor = /* GraphQL */ `
  query GetAdvisor($id: ID!) {
    getAdvisor(id: $id) {
      id
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      comment
      hashtags
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      isViewAdvisorInfo
      advisorTimesheet {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          tableType
          isFixedTimeSetting
          dayOfWeek
          startTime
          endTime
          isVisibility
          updatedAt
          createdAt
          advisorAdvisorTimesheetId
        }
        nextToken
      }
      questions {
        label
        value
      }
      timePrice
      talkCount
      reviewScore
      reviewCount
      favoriteCount
      advisorFinancialInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        bankAccountInfoId
        lastDepositStatus
        lastDepositDate
        cumulativeAmount
        presentBalance
        systemCommission
        createdAt
        updatedAt
        advisorFinancialInfoAdvisorId
      }
      searchNickname
      searchCompany
      searchJobTypeMiddle
      searchCertificate0AuthStatus
      searchCertificate1AuthStatus
      searchCertificate2AuthStatus
      searchCertificate3AuthStatus
      searchCertificate4AuthStatus
      withdraw
      createdAt
      updatedAt
      advisorAdvisorFinancialInfoId
      advisorUserId
    }
  }
`;
export const listAdvisors = /* GraphQL */ `
  query ListAdvisors(
    $filter: ModelAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      nextToken
    }
  }
`;
export const searchAdvisors = /* GraphQL */ `
  query SearchAdvisors(
    $filter: SearchableAdvisorFilterInput
    $sort: [SearchableAdvisorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorAggregationInput]
  ) {
    searchAdvisors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdvisorInfo = /* GraphQL */ `
  query GetAdvisorInfo($id: ID!) {
    getAdvisorInfo(id: $id) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      order
      isContentCompleted
      company
      companyNameMaster {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        sequenceNumber
        corporateNumber
        process
        correct
        updateDate
        changeDate
        name
        nameImageId
        kind
        prefectureName
        cityName
        streetNumber
        addressImageId
        prefectureCode
        cityCode
        postCode
        addressOutside
        addressOutsideImageId
        closeDate
        closeCause
        successorCorporateNumber
        changeCause
        assignmentDate
        latest
        enName
        enPrefectureName
        enCityName
        enAddressOutside
        furigana
        hihyoji
        registeredCount
        version
        createdAt
        updatedAt
      }
      jobType {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        jobTypeLarge
        jobTypeMiddle
        jobTypeLargeDescription
        jobTypeMiddleDescription
        jobTypeMiddleCount
        createdAt
        updatedAt
      }
      description
      yearsOfExperience
      isCompanyVisibility
      career {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        jobTypeLarge
        jobTypeMiddle
        jobGrade
        joiningDate
        leavingDate
        updatedAt
        createdAt
        careerAdvisorInfoId
      }
      authentication {
        items {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          order
          authType
          authStatus
          documentUrl
          adminName
          adminComment
          updatedAt
          createdAt
          advisorInfoAuthenticationId
        }
        nextToken
      }
      updatedAt
      createdAt
      advisorAdvisorInfoId
      companyNameMasterAdvisorInfoId
      jobTypeAdvisorInfoId
      advisorInfoCareerId
    }
  }
`;
export const listAdvisorInfos = /* GraphQL */ `
  query ListAdvisorInfos(
    $filter: ModelAdvisorInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisorInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      nextToken
    }
  }
`;
export const searchAdvisorInfos = /* GraphQL */ `
  query SearchAdvisorInfos(
    $filter: SearchableAdvisorInfoFilterInput
    $sort: [SearchableAdvisorInfoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorInfoAggregationInput]
  ) {
    searchAdvisorInfos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdvisorTimesheet = /* GraphQL */ `
  query GetAdvisorTimesheet($id: ID!) {
    getAdvisorTimesheet(id: $id) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      tableType
      isFixedTimeSetting
      dayOfWeek
      startTime
      endTime
      isVisibility
      updatedAt
      createdAt
      advisorAdvisorTimesheetId
    }
  }
`;
export const listAdvisorTimesheets = /* GraphQL */ `
  query ListAdvisorTimesheets(
    $filter: ModelAdvisorTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisorTimesheets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        tableType
        isFixedTimeSetting
        dayOfWeek
        startTime
        endTime
        isVisibility
        updatedAt
        createdAt
        advisorAdvisorTimesheetId
      }
      nextToken
    }
  }
`;
export const searchAdvisorTimesheets = /* GraphQL */ `
  query SearchAdvisorTimesheets(
    $filter: SearchableAdvisorTimesheetFilterInput
    $sort: [SearchableAdvisorTimesheetSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorTimesheetAggregationInput]
  ) {
    searchAdvisorTimesheets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        tableType
        isFixedTimeSetting
        dayOfWeek
        startTime
        endTime
        isVisibility
        updatedAt
        createdAt
        advisorAdvisorTimesheetId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      jobTypeLarge
      jobTypeMiddle
      jobGrade
      joiningDate
      leavingDate
      updatedAt
      createdAt
      careerAdvisorInfoId
    }
  }
`;
export const listCareers = /* GraphQL */ `
  query ListCareers(
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        jobTypeLarge
        jobTypeMiddle
        jobGrade
        joiningDate
        leavingDate
        updatedAt
        createdAt
        careerAdvisorInfoId
      }
      nextToken
    }
  }
`;
export const searchCareers = /* GraphQL */ `
  query SearchCareers(
    $filter: SearchableCareerFilterInput
    $sort: [SearchableCareerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCareerAggregationInput]
  ) {
    searchCareers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        jobTypeLarge
        jobTypeMiddle
        jobGrade
        joiningDate
        leavingDate
        updatedAt
        createdAt
        careerAdvisorInfoId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAuthentication = /* GraphQL */ `
  query GetAuthentication($id: ID!) {
    getAuthentication(id: $id) {
      id
      advisorInfo {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        order
        isContentCompleted
        company
        companyNameMaster {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          sequenceNumber
          corporateNumber
          process
          correct
          updateDate
          changeDate
          name
          nameImageId
          kind
          prefectureName
          cityName
          streetNumber
          addressImageId
          prefectureCode
          cityCode
          postCode
          addressOutside
          addressOutsideImageId
          closeDate
          closeCause
          successorCorporateNumber
          changeCause
          assignmentDate
          latest
          enName
          enPrefectureName
          enCityName
          enAddressOutside
          furigana
          hihyoji
          registeredCount
          version
          createdAt
          updatedAt
        }
        jobType {
          id
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          jobTypeLarge
          jobTypeMiddle
          jobTypeLargeDescription
          jobTypeMiddleDescription
          jobTypeMiddleCount
          createdAt
          updatedAt
        }
        description
        yearsOfExperience
        isCompanyVisibility
        career {
          id
          advisorInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          jobTypeLarge
          jobTypeMiddle
          jobGrade
          joiningDate
          leavingDate
          updatedAt
          createdAt
          careerAdvisorInfoId
        }
        authentication {
          items {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            order
            authType
            authStatus
            documentUrl
            adminName
            adminComment
            updatedAt
            createdAt
            advisorInfoAuthenticationId
          }
          nextToken
        }
        updatedAt
        createdAt
        advisorAdvisorInfoId
        companyNameMasterAdvisorInfoId
        jobTypeAdvisorInfoId
        advisorInfoCareerId
      }
      order
      authType
      authStatus
      documentUrl
      adminName
      adminComment
      updatedAt
      createdAt
      advisorInfoAuthenticationId
    }
  }
`;
export const listAuthentications = /* GraphQL */ `
  query ListAuthentications(
    $filter: ModelAuthenticationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthentications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        order
        authType
        authStatus
        documentUrl
        adminName
        adminComment
        updatedAt
        createdAt
        advisorInfoAuthenticationId
      }
      nextToken
    }
  }
`;
export const searchAuthentications = /* GraphQL */ `
  query SearchAuthentications(
    $filter: SearchableAuthenticationFilterInput
    $sort: [SearchableAuthenticationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAuthenticationAggregationInput]
  ) {
    searchAuthentications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        order
        authType
        authStatus
        documentUrl
        adminName
        adminComment
        updatedAt
        createdAt
        advisorInfoAuthenticationId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getReserve = /* GraphQL */ `
  query GetReserve($id: ID!) {
    getReserve(id: $id) {
      id
      tableType
      startTime
      endTime
      timeDuration
      reservedUserId
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      reservedAdvisorId
      advisor {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      talkRoomStatus
      talkThemeSelectedInputs
      talkThemeDirectInput
      rtcToken
      cancelId
      cancelDateTime
      review
      reviewScore
      advisorMoneyAmount
      advisorSystemCommission
      systemMoneyAmount
      payment {
        id
        paymentId
        endpointType
        sourceId
        customerId
        status
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        processingFeeMoneyAmount
        referenceId
        delayAction
        delayedUntil
        sourceType
        receiptNumber
        receiptUrl
        paymentReserveId
        orderId
        locationId
        reason
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        ttl
        createdAt
        updatedAt
      }
      chat {
        items {
          id
          reserveChatId
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          content
          fileUrl
          postUserId
          isSent
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isVoiceErrorUser
      isVoiceErrorAdvisor
      ttl
      createdAt
      updatedAt
      userReservedUserId
      userReservedAdvisorId
      reservePaymentId
    }
  }
`;
export const listReserves = /* GraphQL */ `
  query ListReserves(
    $filter: ModelReserveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReserves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      nextToken
    }
  }
`;
export const searchReserves = /* GraphQL */ `
  query SearchReserves(
    $filter: SearchableReserveFilterInput
    $sort: [SearchableReserveSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableReserveAggregationInput]
  ) {
    searchReserves(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      reserveChatId
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      content
      fileUrl
      postUserId
      isSent
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reserveChatId
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        content
        fileUrl
        postUserId
        isSent
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchChats = /* GraphQL */ `
  query SearchChats(
    $filter: SearchableChatFilterInput
    $sort: [SearchableChatSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableChatAggregationInput]
  ) {
    searchChats(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        reserveChatId
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        content
        fileUrl
        postUserId
        isSent
        ttl
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      paymentId
      endpointType
      sourceId
      customerId
      status
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      processingFeeMoneyAmount
      referenceId
      delayAction
      delayedUntil
      sourceType
      receiptNumber
      receiptUrl
      paymentReserveId
      orderId
      locationId
      reason
      reserve {
        id
        tableType
        startTime
        endTime
        timeDuration
        reservedUserId
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        reservedAdvisorId
        advisor {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        talkRoomStatus
        talkThemeSelectedInputs
        talkThemeDirectInput
        rtcToken
        cancelId
        cancelDateTime
        review
        reviewScore
        advisorMoneyAmount
        advisorSystemCommission
        systemMoneyAmount
        payment {
          id
          paymentId
          endpointType
          sourceId
          customerId
          status
          amountMoneyAmount
          amountMoneyCurrency
          approvedMoneyAmount
          totalMoneyAmount
          processingFeeMoneyAmount
          referenceId
          delayAction
          delayedUntil
          sourceType
          receiptNumber
          receiptUrl
          paymentReserveId
          orderId
          locationId
          reason
          reserve {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          ttl
          createdAt
          updatedAt
        }
        chat {
          items {
            id
            reserveChatId
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            content
            fileUrl
            postUserId
            isSent
            ttl
            createdAt
            updatedAt
          }
          nextToken
        }
        isVoiceErrorUser
        isVoiceErrorAdvisor
        ttl
        createdAt
        updatedAt
        userReservedUserId
        userReservedAdvisorId
        reservePaymentId
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paymentId
        endpointType
        sourceId
        customerId
        status
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        processingFeeMoneyAmount
        referenceId
        delayAction
        delayedUntil
        sourceType
        receiptNumber
        receiptUrl
        paymentReserveId
        orderId
        locationId
        reason
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchPayments = /* GraphQL */ `
  query SearchPayments(
    $filter: SearchablePaymentFilterInput
    $sort: [SearchablePaymentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePaymentAggregationInput]
  ) {
    searchPayments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        paymentId
        endpointType
        sourceId
        customerId
        status
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        processingFeeMoneyAmount
        referenceId
        delayAction
        delayedUntil
        sourceType
        receiptNumber
        receiptUrl
        paymentReserveId
        orderId
        locationId
        reason
        reserve {
          id
          tableType
          startTime
          endTime
          timeDuration
          reservedUserId
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          reservedAdvisorId
          advisor {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          talkRoomStatus
          talkThemeSelectedInputs
          talkThemeDirectInput
          rtcToken
          cancelId
          cancelDateTime
          review
          reviewScore
          advisorMoneyAmount
          advisorSystemCommission
          systemMoneyAmount
          payment {
            id
            paymentId
            endpointType
            sourceId
            customerId
            status
            amountMoneyAmount
            amountMoneyCurrency
            approvedMoneyAmount
            totalMoneyAmount
            processingFeeMoneyAmount
            referenceId
            delayAction
            delayedUntil
            sourceType
            receiptNumber
            receiptUrl
            paymentReserveId
            orderId
            locationId
            reason
            reserve {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            ttl
            createdAt
            updatedAt
          }
          chat {
            items {
              id
              reserveChatId
              content
              fileUrl
              postUserId
              isSent
              ttl
              createdAt
              updatedAt
            }
            nextToken
          }
          isVoiceErrorUser
          isVoiceErrorAdvisor
          ttl
          createdAt
          updatedAt
          userReservedUserId
          userReservedAdvisorId
          reservePaymentId
        }
        ttl
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPaymentHistory = /* GraphQL */ `
  query GetPaymentHistory($id: ID!) {
    getPaymentHistory(id: $id) {
      paymentId
      endpointType
      status
      idempotencyKey
      sourceId
      customerId
      amountMoneyAmount
      amountMoneyCurrency
      approvedMoneyAmount
      totalMoneyAmount
      delayAction
      delayedUntil
      sourceType
      paymentCreatedAt
      paymentUpdatedAt
      ttl
      createdAt
      updatedAt
      id
    }
  }
`;
export const listPaymentHistories = /* GraphQL */ `
  query ListPaymentHistories(
    $filter: ModelPaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        paymentId
        endpointType
        status
        idempotencyKey
        sourceId
        customerId
        amountMoneyAmount
        amountMoneyCurrency
        approvedMoneyAmount
        totalMoneyAmount
        delayAction
        delayedUntil
        sourceType
        paymentCreatedAt
        paymentUpdatedAt
        ttl
        createdAt
        updatedAt
        id
      }
      nextToken
    }
  }
`;
export const getPaymentCard = /* GraphQL */ `
  query GetPaymentCard($id: ID!) {
    getPaymentCard(id: $id) {
      id
      sourceId
      customerId
      referenceId
      cardBrand
      last4
      expMonth
      expYear
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentCards = /* GraphQL */ `
  query ListPaymentCards(
    $filter: ModelPaymentCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sourceId
        customerId
        referenceId
        cardBrand
        last4
        expMonth
        expYear
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyNameMaster = /* GraphQL */ `
  query GetCompanyNameMaster($id: ID!) {
    getCompanyNameMaster(id: $id) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      sequenceNumber
      corporateNumber
      process
      correct
      updateDate
      changeDate
      name
      nameImageId
      kind
      prefectureName
      cityName
      streetNumber
      addressImageId
      prefectureCode
      cityCode
      postCode
      addressOutside
      addressOutsideImageId
      closeDate
      closeCause
      successorCorporateNumber
      changeCause
      assignmentDate
      latest
      enName
      enPrefectureName
      enCityName
      enAddressOutside
      furigana
      hihyoji
      registeredCount
      version
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyNameMasters = /* GraphQL */ `
  query ListCompanyNameMasters(
    $filter: ModelCompanyNameMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyNameMasters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        sequenceNumber
        corporateNumber
        process
        correct
        updateDate
        changeDate
        name
        nameImageId
        kind
        prefectureName
        cityName
        streetNumber
        addressImageId
        prefectureCode
        cityCode
        postCode
        addressOutside
        addressOutsideImageId
        closeDate
        closeCause
        successorCorporateNumber
        changeCause
        assignmentDate
        latest
        enName
        enPrefectureName
        enCityName
        enAddressOutside
        furigana
        hihyoji
        registeredCount
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCompanyNameMasters = /* GraphQL */ `
  query SearchCompanyNameMasters(
    $filter: SearchableCompanyNameMasterFilterInput
    $sort: [SearchableCompanyNameMasterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCompanyNameMasterAggregationInput]
  ) {
    searchCompanyNameMasters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        sequenceNumber
        corporateNumber
        process
        correct
        updateDate
        changeDate
        name
        nameImageId
        kind
        prefectureName
        cityName
        streetNumber
        addressImageId
        prefectureCode
        cityCode
        postCode
        addressOutside
        addressOutsideImageId
        closeDate
        closeCause
        successorCorporateNumber
        changeCause
        assignmentDate
        latest
        enName
        enPrefectureName
        enCityName
        enAddressOutside
        furigana
        hihyoji
        registeredCount
        version
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isAdmin
        adminName
        email
        phoneNumber
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBankMaster = /* GraphQL */ `
  query GetBankMaster($id: ID!) {
    getBankMaster(id: $id) {
      id
      code
      name
      halfWidthKana
      fullWidthKana
      hiragana
      businessTypeCode
      businessType
      createdAt
      updatedAt
    }
  }
`;
export const listBankMasters = /* GraphQL */ `
  query ListBankMasters(
    $filter: ModelBankMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        halfWidthKana
        fullWidthKana
        hiragana
        businessTypeCode
        businessType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchBankMasters = /* GraphQL */ `
  query SearchBankMasters(
    $filter: SearchableBankMasterFilterInput
    $sort: [SearchableBankMasterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBankMasterAggregationInput]
  ) {
    searchBankMasters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        code
        name
        halfWidthKana
        fullWidthKana
        hiragana
        businessTypeCode
        businessType
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getDepositServiceSetting = /* GraphQL */ `
  query GetDepositServiceSetting($id: ID!) {
    getDepositServiceSetting(id: $id) {
      id
      depositMinAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      bankTransferRefundCommission
      oneMonthMaxAmount
      shopId
      shopPass
      createdAt
      updatedAt
    }
  }
`;
export const listDepositServiceSettings = /* GraphQL */ `
  query ListDepositServiceSettings(
    $filter: ModelDepositServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepositServiceSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        depositMinAmount
        lessThirtyThousandYenCommission
        notLessThirtyThousandYenCommission
        errorCommission
        bankTransferRefundCommission
        oneMonthMaxAmount
        shopId
        shopPass
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHolidayMaster = /* GraphQL */ `
  query GetHolidayMaster($id: ID!) {
    getHolidayMaster(id: $id) {
      id
      holidayDate
      holidayName
      note
      createdAt
      updatedAt
    }
  }
`;
export const listHolidayMasters = /* GraphQL */ `
  query ListHolidayMasters(
    $filter: ModelHolidayMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolidayMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        holidayDate
        holidayName
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchHolidayMasters = /* GraphQL */ `
  query SearchHolidayMasters(
    $filter: SearchableHolidayMasterFilterInput
    $sort: [SearchableHolidayMasterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHolidayMasterAggregationInput]
  ) {
    searchHolidayMasters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        holidayDate
        holidayName
        note
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdvisorFinancialInfo = /* GraphQL */ `
  query GetAdvisorFinancialInfo($id: ID!) {
    getAdvisorFinancialInfo(id: $id) {
      id
      advisor {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      bankAccountInfoId
      lastDepositStatus
      lastDepositDate
      cumulativeAmount
      presentBalance
      systemCommission
      createdAt
      updatedAt
      advisorFinancialInfoAdvisorId
    }
  }
`;
export const listAdvisorFinancialInfos = /* GraphQL */ `
  query ListAdvisorFinancialInfos(
    $filter: ModelAdvisorFinancialInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvisorFinancialInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        bankAccountInfoId
        lastDepositStatus
        lastDepositDate
        cumulativeAmount
        presentBalance
        systemCommission
        createdAt
        updatedAt
        advisorFinancialInfoAdvisorId
      }
      nextToken
    }
  }
`;
export const searchAdvisorFinancialInfos = /* GraphQL */ `
  query SearchAdvisorFinancialInfos(
    $filter: SearchableAdvisorFinancialInfoFilterInput
    $sort: [SearchableAdvisorFinancialInfoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorFinancialInfoAggregationInput]
  ) {
    searchAdvisorFinancialInfos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        bankAccountInfoId
        lastDepositStatus
        lastDepositDate
        cumulativeAmount
        presentBalance
        systemCommission
        createdAt
        updatedAt
        advisorFinancialInfoAdvisorId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getDepositHistory = /* GraphQL */ `
  query GetDepositHistory($id: ID!) {
    getDepositHistory(id: $id) {
      id
      advisorId
      bankAccountInfoId
      depositId
      depositStatus
      depositAmount
      depositBankFee
      depositStatusUpdateInfo {
        depositStatus
        statusUpdateTime
      }
      depositExpectedDate
      closingDate
      errorResultDetail
      errorCommission
      bankName
      branchCode
      accountTypeName
      accountNumberLast3
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listDepositHistories = /* GraphQL */ `
  query ListDepositHistories(
    $filter: ModelDepositHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepositHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisorId
        bankAccountInfoId
        depositId
        depositStatus
        depositAmount
        depositBankFee
        depositStatusUpdateInfo {
          depositStatus
          statusUpdateTime
        }
        depositExpectedDate
        closingDate
        errorResultDetail
        errorCommission
        bankName
        branchCode
        accountTypeName
        accountNumberLast3
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchDepositHistories = /* GraphQL */ `
  query SearchDepositHistories(
    $filter: SearchableDepositHistoryFilterInput
    $sort: [SearchableDepositHistorySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDepositHistoryAggregationInput]
  ) {
    searchDepositHistories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorId
        bankAccountInfoId
        depositId
        depositStatus
        depositAmount
        depositBankFee
        depositStatusUpdateInfo {
          depositStatus
          statusUpdateTime
        }
        depositExpectedDate
        closingDate
        errorResultDetail
        errorCommission
        bankName
        branchCode
        accountTypeName
        accountNumberLast3
        ttl
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getDeposit = /* GraphQL */ `
  query GetDeposit($id: ID!) {
    getDeposit(id: $id) {
      id
      depositMinAmount
      processCategory
      advisorId
      bankId
      depositId
      accountNumber
      accountType
      accountName
      bankCode
      bankName
      branchCode
      branchName
      lastDepositStatus
      presentBalance
      depositApplicableAmount
      depositAmount
      depositBankFee
      depositExpectedDate
      closingDate
      errorDetail
      beginsWithHiraganaBankName
      depositHistoryView {
        depositId
        depositHistoryId
        depositStatus
        applicationDateTime
        depositApplicationAmountTotal
        depositBankFee
        depositAmount
        depositExpectedDate
        bankName
        branchCode
        accountTypeName
        accountNumberLast3
        closingDate
        errorResultDetail
        errorCommission
      }
      bankInfos {
        bankCode
        bankName
        bankHiragana
      }
      bigBanks {
        bankCode
        bankName
        bankHiragana
      }
      oneMonthMaxAmount
      lessThirtyThousandYenCommission
      notLessThirtyThousandYenCommission
      errorCommission
      lastEvaluatedKey
      note
      createdAt
      updatedAt
    }
  }
`;
export const listDeposits = /* GraphQL */ `
  query ListDeposits(
    $filter: ModelDepositFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeposits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        depositMinAmount
        processCategory
        advisorId
        bankId
        depositId
        accountNumber
        accountType
        accountName
        bankCode
        bankName
        branchCode
        branchName
        lastDepositStatus
        presentBalance
        depositApplicableAmount
        depositAmount
        depositBankFee
        depositExpectedDate
        closingDate
        errorDetail
        beginsWithHiraganaBankName
        depositHistoryView {
          depositId
          depositHistoryId
          depositStatus
          applicationDateTime
          depositApplicationAmountTotal
          depositBankFee
          depositAmount
          depositExpectedDate
          bankName
          branchCode
          accountTypeName
          accountNumberLast3
          closingDate
          errorResultDetail
          errorCommission
        }
        bankInfos {
          bankCode
          bankName
          bankHiragana
        }
        bigBanks {
          bankCode
          bankName
          bankHiragana
        }
        oneMonthMaxAmount
        lessThirtyThousandYenCommission
        notLessThirtyThousandYenCommission
        errorCommission
        lastEvaluatedKey
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryMaster = /* GraphQL */ `
  query GetCategoryMaster($id: ID!) {
    getCategoryMaster(id: $id) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const listCategoryMasters = /* GraphQL */ `
  query ListCategoryMasters(
    $filter: ModelCategoryMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        receptionNumber
        nickname
        email
        title
        content
        imageUrl
        status
        createdAt
        updatedAt
        contactCategoryId
      }
      nextToken
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: [SearchableContactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableContactAggregationInput]
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        receptionNumber
        nickname
        email
        title
        content
        imageUrl
        status
        createdAt
        updatedAt
        contactCategoryId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($id: ID!) {
    getFaq(id: $id) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        title
        content
        imageUrl
        isView
        createdAt
        updatedAt
        faqCategoryId
      }
      nextToken
    }
  }
`;
export const searchFaqs = /* GraphQL */ `
  query SearchFaqs(
    $filter: SearchableFaqFilterInput
    $sort: [SearchableFaqSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableFaqAggregationInput]
  ) {
    searchFaqs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        title
        content
        imageUrl
        isView
        createdAt
        updatedAt
        faqCategoryId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        content
        imageUrl
        isView
        startTime
        endTime
        viewCount
        createdAt
        updatedAt
        noticeCategoryId
      }
      nextToken
    }
  }
`;
export const searchNotices = /* GraphQL */ `
  query SearchNotices(
    $filter: SearchableNoticeFilterInput
    $sort: [SearchableNoticeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNoticeAggregationInput]
  ) {
    searchNotices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        content
        imageUrl
        isView
        startTime
        endTime
        viewCount
        createdAt
        updatedAt
        noticeCategoryId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getNotificationHistory = /* GraphQL */ `
  query GetNotificationHistory($id: ID!) {
    getNotificationHistory(id: $id) {
      id
      tableName
      tableId
      userId
      senderId
      senderProfileUrl
      token
      title
      body
      badge
      category
      isConfirm
      imageUrl
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationHistories = /* GraphQL */ `
  query ListNotificationHistories(
    $filter: ModelNotificationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tableName
        tableId
        userId
        senderId
        senderProfileUrl
        token
        title
        body
        badge
        category
        isConfirm
        imageUrl
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchNotificationHistories = /* GraphQL */ `
  query SearchNotificationHistories(
    $filter: SearchableNotificationHistoryFilterInput
    $sort: [SearchableNotificationHistorySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNotificationHistoryAggregationInput]
  ) {
    searchNotificationHistories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        tableName
        tableId
        userId
        senderId
        senderProfileUrl
        token
        title
        body
        badge
        category
        isConfirm
        imageUrl
        ttl
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getBankAccountInfo = /* GraphQL */ `
  query GetBankAccountInfo($id: ID!) {
    getBankAccountInfo(id: $id) {
      id
      advisorId
      bankCode
      bankName
      branchCode
      accountType
      accountNumber
      accountName
      accountStatus
      createdAt
      updatedAt
    }
  }
`;
export const listBankAccountInfos = /* GraphQL */ `
  query ListBankAccountInfos(
    $filter: ModelBankAccountInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankAccountInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisorId
        bankCode
        bankName
        branchCode
        accountType
        accountNumber
        accountName
        accountStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobType = /* GraphQL */ `
  query GetJobType($id: ID!) {
    getJobType(id: $id) {
      id
      advisorInfo {
        items {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          order
          isContentCompleted
          company
          companyNameMaster {
            id
            advisorInfo {
              nextToken
            }
            sequenceNumber
            corporateNumber
            process
            correct
            updateDate
            changeDate
            name
            nameImageId
            kind
            prefectureName
            cityName
            streetNumber
            addressImageId
            prefectureCode
            cityCode
            postCode
            addressOutside
            addressOutsideImageId
            closeDate
            closeCause
            successorCorporateNumber
            changeCause
            assignmentDate
            latest
            enName
            enPrefectureName
            enCityName
            enAddressOutside
            furigana
            hihyoji
            registeredCount
            version
            createdAt
            updatedAt
          }
          jobType {
            id
            advisorInfo {
              nextToken
            }
            jobTypeLarge
            jobTypeMiddle
            jobTypeLargeDescription
            jobTypeMiddleDescription
            jobTypeMiddleCount
            createdAt
            updatedAt
          }
          description
          yearsOfExperience
          isCompanyVisibility
          career {
            id
            advisorInfo {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            jobTypeLarge
            jobTypeMiddle
            jobGrade
            joiningDate
            leavingDate
            updatedAt
            createdAt
            careerAdvisorInfoId
          }
          authentication {
            items {
              id
              order
              authType
              authStatus
              documentUrl
              adminName
              adminComment
              updatedAt
              createdAt
              advisorInfoAuthenticationId
            }
            nextToken
          }
          updatedAt
          createdAt
          advisorAdvisorInfoId
          companyNameMasterAdvisorInfoId
          jobTypeAdvisorInfoId
          advisorInfoCareerId
        }
        nextToken
      }
      jobTypeLarge
      jobTypeMiddle
      jobTypeLargeDescription
      jobTypeMiddleDescription
      jobTypeMiddleCount
      createdAt
      updatedAt
    }
  }
`;
export const listJobTypes = /* GraphQL */ `
  query ListJobTypes(
    $filter: ModelJobTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        jobTypeLarge
        jobTypeMiddle
        jobTypeLargeDescription
        jobTypeMiddleDescription
        jobTypeMiddleCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchJobTypes = /* GraphQL */ `
  query SearchJobTypes(
    $filter: SearchableJobTypeFilterInput
    $sort: [SearchableJobTypeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableJobTypeAggregationInput]
  ) {
    searchJobTypes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        jobTypeLarge
        jobTypeMiddle
        jobTypeLargeDescription
        jobTypeMiddleDescription
        jobTypeMiddleCount
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getBankMasterHistory = /* GraphQL */ `
  query GetBankMasterHistory($id: ID!) {
    getBankMasterHistory(id: $id) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const listBankMasterHistories = /* GraphQL */ `
  query ListBankMasterHistories(
    $filter: ModelBankMasterHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankMasterHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileUrl
        status
        adminName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProcess = /* GraphQL */ `
  query GetProcess($id: ID!) {
    getProcess(id: $id) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const listProcesses = /* GraphQL */ `
  query ListProcesses(
    $filter: ModelProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProcesses = /* GraphQL */ `
  query SearchProcesses(
    $filter: SearchableProcessFilterInput
    $sort: [SearchableProcessSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProcessAggregationInput]
  ) {
    searchProcesses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        content
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getEventBanner = /* GraphQL */ `
  query GetEventBanner($id: ID!) {
    getEventBanner(id: $id) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const listEventBanners = /* GraphQL */ `
  query ListEventBanners(
    $filter: ModelEventBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        pageUrl
        imageUrl
        isView
        startTime
        endTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchEventBanners = /* GraphQL */ `
  query SearchEventBanners(
    $filter: SearchableEventBannerFilterInput
    $sort: [SearchableEventBannerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEventBannerAggregationInput]
  ) {
    searchEventBanners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        pageUrl
        imageUrl
        isView
        startTime
        endTime
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPopularityAdvisor = /* GraphQL */ `
  query GetPopularityAdvisor($id: ID!) {
    getPopularityAdvisor(id: $id) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      popularityAdvisorAdvisorIdId
    }
  }
`;
export const listPopularityAdvisors = /* GraphQL */ `
  query ListPopularityAdvisors(
    $filter: ModelPopularityAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPopularityAdvisors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisorId {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        status
        createdAt
        updatedAt
        popularityAdvisorAdvisorIdId
      }
      nextToken
    }
  }
`;
export const searchPopularityAdvisors = /* GraphQL */ `
  query SearchPopularityAdvisors(
    $filter: SearchablePopularityAdvisorFilterInput
    $sort: [SearchablePopularityAdvisorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePopularityAdvisorAggregationInput]
  ) {
    searchPopularityAdvisors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorId {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        status
        createdAt
        updatedAt
        popularityAdvisorAdvisorIdId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getRecommendAdvisor = /* GraphQL */ `
  query GetRecommendAdvisor($id: ID!) {
    getRecommendAdvisor(id: $id) {
      id
      advisorId {
        id
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        comment
        hashtags
        advisorInfo {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            order
            isContentCompleted
            company
            companyNameMaster {
              id
              sequenceNumber
              corporateNumber
              process
              correct
              updateDate
              changeDate
              name
              nameImageId
              kind
              prefectureName
              cityName
              streetNumber
              addressImageId
              prefectureCode
              cityCode
              postCode
              addressOutside
              addressOutsideImageId
              closeDate
              closeCause
              successorCorporateNumber
              changeCause
              assignmentDate
              latest
              enName
              enPrefectureName
              enCityName
              enAddressOutside
              furigana
              hihyoji
              registeredCount
              version
              createdAt
              updatedAt
            }
            jobType {
              id
              jobTypeLarge
              jobTypeMiddle
              jobTypeLargeDescription
              jobTypeMiddleDescription
              jobTypeMiddleCount
              createdAt
              updatedAt
            }
            description
            yearsOfExperience
            isCompanyVisibility
            career {
              id
              jobTypeLarge
              jobTypeMiddle
              jobGrade
              joiningDate
              leavingDate
              updatedAt
              createdAt
              careerAdvisorInfoId
            }
            authentication {
              nextToken
            }
            updatedAt
            createdAt
            advisorAdvisorInfoId
            companyNameMasterAdvisorInfoId
            jobTypeAdvisorInfoId
            advisorInfoCareerId
          }
          nextToken
        }
        isViewAdvisorInfo
        advisorTimesheet {
          items {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            tableType
            isFixedTimeSetting
            dayOfWeek
            startTime
            endTime
            isVisibility
            updatedAt
            createdAt
            advisorAdvisorTimesheetId
          }
          nextToken
        }
        questions {
          label
          value
        }
        timePrice
        talkCount
        reviewScore
        reviewCount
        favoriteCount
        advisorFinancialInfo {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          bankAccountInfoId
          lastDepositStatus
          lastDepositDate
          cumulativeAmount
          presentBalance
          systemCommission
          createdAt
          updatedAt
          advisorFinancialInfoAdvisorId
        }
        searchNickname
        searchCompany
        searchJobTypeMiddle
        searchCertificate0AuthStatus
        searchCertificate1AuthStatus
        searchCertificate2AuthStatus
        searchCertificate3AuthStatus
        searchCertificate4AuthStatus
        withdraw
        createdAt
        updatedAt
        advisorAdvisorFinancialInfoId
        advisorUserId
      }
      status
      createdAt
      updatedAt
      recommendAdvisorAdvisorIdId
    }
  }
`;
export const listRecommendAdvisors = /* GraphQL */ `
  query ListRecommendAdvisors(
    $filter: ModelRecommendAdvisorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommendAdvisors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advisorId {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        status
        createdAt
        updatedAt
        recommendAdvisorAdvisorIdId
      }
      nextToken
    }
  }
`;
export const searchRecommendAdvisors = /* GraphQL */ `
  query SearchRecommendAdvisors(
    $filter: SearchableRecommendAdvisorFilterInput
    $sort: [SearchableRecommendAdvisorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRecommendAdvisorAggregationInput]
  ) {
    searchRecommendAdvisors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        advisorId {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        status
        createdAt
        updatedAt
        recommendAdvisorAdvisorIdId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCompanyNameMasterHistory = /* GraphQL */ `
  query GetCompanyNameMasterHistory($id: ID!) {
    getCompanyNameMasterHistory(id: $id) {
      id
      fileUrl
      status
      adminName
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyNameMasterHistories = /* GraphQL */ `
  query ListCompanyNameMasterHistories(
    $filter: ModelCompanyNameMasterHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyNameMasterHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileUrl
        status
        adminName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStatusCodeMaster = /* GraphQL */ `
  query GetStatusCodeMaster($id: ID!) {
    getStatusCodeMaster(id: $id) {
      id
      service
      statusCode
      statusDetail
      description
      skServiceStatusCode
      createdAt
      updatedAt
    }
  }
`;
export const listStatusCodeMasters = /* GraphQL */ `
  query ListStatusCodeMasters(
    $filter: ModelStatusCodeMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatusCodeMasters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        service
        statusCode
        statusDetail
        description
        skServiceStatusCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchStatusCodeMasters = /* GraphQL */ `
  query SearchStatusCodeMasters(
    $filter: SearchableStatusCodeMasterFilterInput
    $sort: [SearchableStatusCodeMasterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableStatusCodeMasterAggregationInput]
  ) {
    searchStatusCodeMasters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        service
        statusCode
        statusDetail
        description
        skServiceStatusCode
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      urlCode
      price
      expirationDate
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      isRead
      isSend
      isDisable
      stepFunctionId
      couponType
      contents
      promotionHistory {
        id
        coupon {
          id
          urlCode
          price
          expirationDate
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          isRead
          isSend
          isDisable
          stepFunctionId
          couponType
          contents
          promotionHistory {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          createdAt
          updatedAt
          couponPromotionId
          couponPromotionHistoryId
        }
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        contents
        createdAt
        updatedAt
        userPromotionHistoryId
        promotionPromotionHistoryId
        promotionHistoryCouponId
      }
      createdAt
      updatedAt
      couponPromotionId
      couponPromotionHistoryId
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        urlCode
        price
        expirationDate
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isRead
        isSend
        isDisable
        stepFunctionId
        couponType
        contents
        promotionHistory {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        createdAt
        updatedAt
        couponPromotionId
        couponPromotionHistoryId
      }
      nextToken
    }
  }
`;
export const searchCoupons = /* GraphQL */ `
  query SearchCoupons(
    $filter: SearchableCouponFilterInput
    $sort: [SearchableCouponSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCouponAggregationInput]
  ) {
    searchCoupons(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        urlCode
        price
        expirationDate
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isRead
        isSend
        isDisable
        stepFunctionId
        couponType
        contents
        promotionHistory {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        createdAt
        updatedAt
        couponPromotionId
        couponPromotionHistoryId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      title
      description
      target
      messageOption
      option
      fixedCount
      isAuto
      status
      couponType
      startTime
      endTime
      contents
      promotionHistory {
        items {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPromotions = /* GraphQL */ `
  query ListPromotions(
    $filter: ModelPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchPromotions = /* GraphQL */ `
  query SearchPromotions(
    $filter: SearchablePromotionFilterInput
    $sort: [SearchablePromotionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePromotionAggregationInput]
  ) {
    searchPromotions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPromotionHistory = /* GraphQL */ `
  query GetPromotionHistory($id: ID!) {
    getPromotionHistory(id: $id) {
      id
      coupon {
        id
        urlCode
        price
        expirationDate
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isRead
        isSend
        isDisable
        stepFunctionId
        couponType
        contents
        promotionHistory {
          id
          coupon {
            id
            urlCode
            price
            expirationDate
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            isRead
            isSend
            isDisable
            stepFunctionId
            couponType
            contents
            promotionHistory {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            createdAt
            updatedAt
            couponPromotionId
            couponPromotionHistoryId
          }
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          status
          contents
          createdAt
          updatedAt
          userPromotionHistoryId
          promotionPromotionHistoryId
          promotionHistoryCouponId
        }
        createdAt
        updatedAt
        couponPromotionId
        couponPromotionHistoryId
      }
      user {
        id
        advisor {
          id
          user {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            fcmToken
            platformDeviceId
            nickname
            familyName
            firstName
            familyNameKana
            firstNameKana
            birthdate
            countryCode
            phoneNumber
            profileUrl
            timeZone
            squareCustomerId
            withdraw
            reservedUser {
              nextToken
            }
            reservedAdvisor {
              nextToken
            }
            promotionHistory {
              nextToken
            }
            ttl
            createdAt
            updatedAt
            userAdvisorId
          }
          comment
          hashtags
          advisorInfo {
            items {
              id
              order
              isContentCompleted
              company
              description
              yearsOfExperience
              isCompanyVisibility
              updatedAt
              createdAt
              advisorAdvisorInfoId
              companyNameMasterAdvisorInfoId
              jobTypeAdvisorInfoId
              advisorInfoCareerId
            }
            nextToken
          }
          isViewAdvisorInfo
          advisorTimesheet {
            items {
              id
              tableType
              isFixedTimeSetting
              dayOfWeek
              startTime
              endTime
              isVisibility
              updatedAt
              createdAt
              advisorAdvisorTimesheetId
            }
            nextToken
          }
          questions {
            label
            value
          }
          timePrice
          talkCount
          reviewScore
          reviewCount
          favoriteCount
          advisorFinancialInfo {
            id
            advisor {
              id
              comment
              hashtags
              isViewAdvisorInfo
              timePrice
              talkCount
              reviewScore
              reviewCount
              favoriteCount
              searchNickname
              searchCompany
              searchJobTypeMiddle
              searchCertificate0AuthStatus
              searchCertificate1AuthStatus
              searchCertificate2AuthStatus
              searchCertificate3AuthStatus
              searchCertificate4AuthStatus
              withdraw
              createdAt
              updatedAt
              advisorAdvisorFinancialInfoId
              advisorUserId
            }
            bankAccountInfoId
            lastDepositStatus
            lastDepositDate
            cumulativeAmount
            presentBalance
            systemCommission
            createdAt
            updatedAt
            advisorFinancialInfoAdvisorId
          }
          searchNickname
          searchCompany
          searchJobTypeMiddle
          searchCertificate0AuthStatus
          searchCertificate1AuthStatus
          searchCertificate2AuthStatus
          searchCertificate3AuthStatus
          searchCertificate4AuthStatus
          withdraw
          createdAt
          updatedAt
          advisorAdvisorFinancialInfoId
          advisorUserId
        }
        fcmToken
        platformDeviceId
        nickname
        familyName
        firstName
        familyNameKana
        firstNameKana
        birthdate
        countryCode
        phoneNumber
        profileUrl
        timeZone
        squareCustomerId
        withdraw
        reservedUser {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        reservedAdvisor {
          items {
            id
            tableType
            startTime
            endTime
            timeDuration
            reservedUserId
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            reservedAdvisorId
            advisor {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            talkRoomStatus
            talkThemeSelectedInputs
            talkThemeDirectInput
            rtcToken
            cancelId
            cancelDateTime
            review
            reviewScore
            advisorMoneyAmount
            advisorSystemCommission
            systemMoneyAmount
            payment {
              id
              paymentId
              endpointType
              sourceId
              customerId
              status
              amountMoneyAmount
              amountMoneyCurrency
              approvedMoneyAmount
              totalMoneyAmount
              processingFeeMoneyAmount
              referenceId
              delayAction
              delayedUntil
              sourceType
              receiptNumber
              receiptUrl
              paymentReserveId
              orderId
              locationId
              reason
              ttl
              createdAt
              updatedAt
            }
            chat {
              nextToken
            }
            isVoiceErrorUser
            isVoiceErrorAdvisor
            ttl
            createdAt
            updatedAt
            userReservedUserId
            userReservedAdvisorId
            reservePaymentId
          }
          nextToken
        }
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        ttl
        createdAt
        updatedAt
        userAdvisorId
      }
      promotion {
        id
        title
        description
        target
        messageOption
        option
        fixedCount
        isAuto
        status
        couponType
        startTime
        endTime
        contents
        promotionHistory {
          items {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      contents
      createdAt
      updatedAt
      userPromotionHistoryId
      promotionPromotionHistoryId
      promotionHistoryCouponId
    }
  }
`;
export const listPromotionHistories = /* GraphQL */ `
  query ListPromotionHistories(
    $filter: ModelPromotionHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromotionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coupon {
          id
          urlCode
          price
          expirationDate
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          isRead
          isSend
          isDisable
          stepFunctionId
          couponType
          contents
          promotionHistory {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          createdAt
          updatedAt
          couponPromotionId
          couponPromotionHistoryId
        }
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        contents
        createdAt
        updatedAt
        userPromotionHistoryId
        promotionPromotionHistoryId
        promotionHistoryCouponId
      }
      nextToken
    }
  }
`;
export const searchPromotionHistories = /* GraphQL */ `
  query SearchPromotionHistories(
    $filter: SearchablePromotionHistoryFilterInput
    $sort: [SearchablePromotionHistorySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePromotionHistoryAggregationInput]
  ) {
    searchPromotionHistories(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        coupon {
          id
          urlCode
          price
          expirationDate
          promotion {
            id
            title
            description
            target
            messageOption
            option
            fixedCount
            isAuto
            status
            couponType
            startTime
            endTime
            contents
            promotionHistory {
              nextToken
            }
            createdAt
            updatedAt
          }
          isRead
          isSend
          isDisable
          stepFunctionId
          couponType
          contents
          promotionHistory {
            id
            coupon {
              id
              urlCode
              price
              expirationDate
              isRead
              isSend
              isDisable
              stepFunctionId
              couponType
              contents
              createdAt
              updatedAt
              couponPromotionId
              couponPromotionHistoryId
            }
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            promotion {
              id
              title
              description
              target
              messageOption
              option
              fixedCount
              isAuto
              status
              couponType
              startTime
              endTime
              contents
              createdAt
              updatedAt
            }
            status
            contents
            createdAt
            updatedAt
            userPromotionHistoryId
            promotionPromotionHistoryId
            promotionHistoryCouponId
          }
          createdAt
          updatedAt
          couponPromotionId
          couponPromotionHistoryId
        }
        user {
          id
          advisor {
            id
            user {
              id
              fcmToken
              platformDeviceId
              nickname
              familyName
              firstName
              familyNameKana
              firstNameKana
              birthdate
              countryCode
              phoneNumber
              profileUrl
              timeZone
              squareCustomerId
              withdraw
              ttl
              createdAt
              updatedAt
              userAdvisorId
            }
            comment
            hashtags
            advisorInfo {
              nextToken
            }
            isViewAdvisorInfo
            advisorTimesheet {
              nextToken
            }
            questions {
              label
              value
            }
            timePrice
            talkCount
            reviewScore
            reviewCount
            favoriteCount
            advisorFinancialInfo {
              id
              bankAccountInfoId
              lastDepositStatus
              lastDepositDate
              cumulativeAmount
              presentBalance
              systemCommission
              createdAt
              updatedAt
              advisorFinancialInfoAdvisorId
            }
            searchNickname
            searchCompany
            searchJobTypeMiddle
            searchCertificate0AuthStatus
            searchCertificate1AuthStatus
            searchCertificate2AuthStatus
            searchCertificate3AuthStatus
            searchCertificate4AuthStatus
            withdraw
            createdAt
            updatedAt
            advisorAdvisorFinancialInfoId
            advisorUserId
          }
          fcmToken
          platformDeviceId
          nickname
          familyName
          firstName
          familyNameKana
          firstNameKana
          birthdate
          countryCode
          phoneNumber
          profileUrl
          timeZone
          squareCustomerId
          withdraw
          reservedUser {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          reservedAdvisor {
            items {
              id
              tableType
              startTime
              endTime
              timeDuration
              reservedUserId
              reservedAdvisorId
              talkRoomStatus
              talkThemeSelectedInputs
              talkThemeDirectInput
              rtcToken
              cancelId
              cancelDateTime
              review
              reviewScore
              advisorMoneyAmount
              advisorSystemCommission
              systemMoneyAmount
              isVoiceErrorUser
              isVoiceErrorAdvisor
              ttl
              createdAt
              updatedAt
              userReservedUserId
              userReservedAdvisorId
              reservePaymentId
            }
            nextToken
          }
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          ttl
          createdAt
          updatedAt
          userAdvisorId
        }
        promotion {
          id
          title
          description
          target
          messageOption
          option
          fixedCount
          isAuto
          status
          couponType
          startTime
          endTime
          contents
          promotionHistory {
            items {
              id
              status
              contents
              createdAt
              updatedAt
              userPromotionHistoryId
              promotionPromotionHistoryId
              promotionHistoryCouponId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        status
        contents
        createdAt
        updatedAt
        userPromotionHistoryId
        promotionPromotionHistoryId
        promotionHistoryCouponId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
