import convenient from "../../assets/img/convenient/convenient.png";
import list from "../../assets/img/list/list.svg";
import React from "react";

export default function Convenient() {
  return (
    <>
      <div
        className="bottom-auto top-0 left-0 right-0 w-full border-0 absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style={{ transform: "translateZ(0)" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div className="container mx-auto px-4">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img
              alt="..."
              className="max-w-full rounded-lg shadow-lg bg-lightBlue-300"
              src={convenient}
            />
          </div>
          <div
            className="w-full md:w-5/12 ml-auto mr-auto px-4"
            style={{ textAlign: "start" }}
          >
            <div className="md:pr-12 mt-10">
              <div className="text-lightBlue-600 text-center inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 mb-6 shadow-lg rounded-lg bg-lightBlue-300">
                <img src={list} alt="" />
              </div>
              <h3 className="text-xl md:text-3xl font-semibold">
                使い方は簡単！
              </h3>
              <p className="mt-4 text-sm md:text-lg leading-relaxed font-bold text-blueGray-500">
                気になる「業界・会社・職種」の現職者を検索し、
                <br /> アドバイスをリクエストするだけ！
                <br />
                アドバイスの時間になったらVoice Chatをはじめる
                <br />
                あなたのお悩みや課題への解決方法がみつかる
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
