import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ListCategoryMastersQuery } from "../../API";
import { searchQuery } from "../../utils/useSearchable";
import * as queries from "../../graphql/queries";
import { useForm } from "react-hook-form";
import { resizeImage } from "../../hooks/resizeImage";
import { Storage } from "aws-amplify";
import { updateMutation } from "../../utils/useMutation";
import * as mutations from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";

type InputContact = {
  nickname: string;
  category: any;
  email: string;
  imageUrl: [];
  title: string;
  content: string;
};

const id = uuidv4();
const fileList: any[] = [];
const fileData: any[] = [];
export default function ContactFormComponent() {
  const [category, setCategory] = useState<
    ListCategoryMastersQuery | undefined | any
  >();
  const navigate = useNavigate();
  useEffect(() => {
    const variables = {
      filter: { typeName: { eq: "Contact" } },
    };
    const categoryData = async () => {
      const listCategory = await searchQuery(
        queries.listCategoryMasters,
        variables
      );
      setCategory(listCategory.data);
    };
    categoryData();
  }, []);
  const createReceptionNumber = () => {
    let tempAlphabet = "";
    let tempNumber = "";
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const number = "0123456789";
    for (let i = 0; i < 4; i++) {
      tempAlphabet += alphabet.charAt(
        Math.floor(Math.random() * alphabet.length)
      );
      tempNumber += number.charAt(Math.floor(Math.random() * number.length));
    }
    return tempAlphabet + tempNumber;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm();

  const onsubmit = async (data: InputContact) => {
    if (fileData.length > 0) {
      for (let i = 0; i < fileData.length; i++) {
        const imageExtension: string = fileData[i].name.split(".").pop();
        const changeImageName = `contact_question_${i}.${imageExtension}`;
        let resizeImages = resizeImage(fileData[i], 1024, 768);
        resizeImages.then((result: any) => {
          Storage.put(
            `contact/${id}/image/question/${changeImageName}`,
            result
          );
        });
        fileList.push(`contact/${id}/image/question/${changeImageName}`);
      }
    }
    const categoryCategoryId: any = JSON.parse(data.category).id;
    const category: any = JSON.parse(data.category).category;
    const inputContact = {
      nickname: data.nickname,
      email: data.email,
      contactCategoryId: categoryCategoryId,
      title: JSON.stringify({ question: data.title }),
      content: JSON.stringify({ question: data.content, category: category }),
    };
    const receptionNumber = createReceptionNumber();
    const contactData = {
      id: id,
      ...inputContact,
      imageUrl: JSON.stringify({ question: fileList ?? "", answer: "" }),
      receptionNumber: receptionNumber,
      status: 0,
    };
    const result = await updateMutation(mutations.createContact, contactData);
    if (result) {
      navigate("/contact/complete");
    }
  };
  const handleFileEvent = (e: any) => {
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        fileData.push(e.target.files[i]);
      }
    }
  };
  return (
    <div className="isolate mx-auto px-4 pt-24 lg:pt-24">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-1/2 -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-40rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <span className="text-2xl md:text-3xl lg:text-4xl font-semibold text-blueGray-600 mb-12">
          お問い合わせ
        </span>
      </div>
      <form method="POST" className="mx-auto max-w-xl">
        <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm text-left font-semibold leading-6 text-gray-900 after:content-['*'] after:text-red-500"
            >
              お名前
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nickname"
                id="nickname"
                className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("nickname", {
                  required: "名前を入力してください。",
                })}
              />
              <p className="text-red-500 text-left">
                {errors.nickname?.message}
              </p>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm text-left font-semibold leading-6 text-gray-900 after:content-['*'] after:text-red-500"
            >
              メールアドレス
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("email", {
                  required: "メールアドレスを入力してください。",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message:
                      "有効なメールアドレスを入力してください。例）example@example.co.jp / example@example.com",
                  },
                })}
              />
              <p className="text-red-500 text-left">{errors.email?.message}</p>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label className="block text-sm text-left font-semibold leading-6 text-gray-900 after:content-['*'] after:text-red-500">
              カテゴリー
            </label>
            <select
              className="block w-1/2 rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              {...register("category", {
                required: "カテゴリーを選択してください。",
              })}
            >
              <option
                className="text-gray-900"
                value=""
                disabled={true}
                selected
              >
                選択
              </option>
              {category?.listCategoryMasters?.items.map((c: any) => {
                return (
                  <option className="text-gray-900" value={JSON.stringify(c)}>
                    {c.category}
                  </option>
                );
              })}
            </select>
            <p className="text-red-500 text-left">{errors.category?.message}</p>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm text-left font-semibold leading-6 text-gray-900 after:content-['*'] after:text-red-500"
            >
              題名
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="title"
                id="title"
                className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("title", {
                  required: "題名を入力してください。",
                })}
              />
              <p className="text-red-500 text-left">{errors.title?.message}</p>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label className="block text-sm text-left font-semibold leading-6 text-gray-900">
              添付イメージ
            </label>
            <div className="mb-10">
              <input
                type="file"
                accept="image/png, image/jpeg"
                className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                multiple
                {...register("imageUrl")}
                onChange={handleFileEvent}
                hidden
              ></input>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm text-left font-semibold leading-6 text-gray-900 after:content-['*'] after:text-red-500"
            >
              メッセージ本文
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                rows={4}
                className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={""}
                {...register("content", {
                  required: "内容を入力してください。",
                })}
              />
              <p className="text-red-500 text-left">
                {errors.content?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit(onsubmit)}
          >
            送信
          </button>
        </div>
      </form>
    </div>
  );
}
