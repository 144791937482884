import React, { useEffect } from "react";
import Navbar from "../components/Navbars/BlackNavbar";
import Footer from "../components/Footers/Footer";
import "../assets/styles/step.css";
import type { FunctionComponent } from "react";

const Step: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="pb-20 relative block bg-blueGray-100">
          <div className="container mx-auto px-4 pt-24 lg:pt-24 pb-20 text-center">
            <div className=" ">
              <div className="w-full px-4">
                <span className="text-2xl md:text-3xl lg:text-4xl font-semibold text-blueGray-600">
                  DanGoTalk利用までさくっとお手軽
                </span>
              </div>
              <div className="w-full px-4">
                <span className="text-2xl md:text-3xl lg:text-4xl font-semibold text-blueGray-600 mb-12">
                  ご利用の流れ
                </span>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center text-blueGray-600">
              <ul className="flow">
                <li>
                  <dl>
                    <dt>
                      <span className="icon">STEP.01</span>アドバイザー検索
                    </dt>
                    <dd className="text-xs">画面の検索バーをタッチします。</dd>
                    <dd className="text-xs">
                      ニックネームやハッシュタグ、会社名を入力してアドバイザーを検索します。
                    </dd>
                    <dd className="text-xs">
                      検索結果で相談を受けたいアドバイザーを選択します。
                    </dd>
                  </dl>
                </li>

                <li>
                  <dl>
                    <dt>
                      <span className="icon">STEP.02</span>日程選択
                    </dt>
                    <dd className="text-xs">
                      アドバイザーのプロフィール内容をお読みします。
                    </dd>
                    <dd className="text-xs">
                      時間選択で相談を受けたい日付及び時間を選択します。
                    </dd>
                    <dd className="text-xs">
                      選択が終わったら「次へ」を押します。
                    </dd>
                  </dl>
                </li>

                <li>
                  <dl>
                    <dt>
                      <span className="icon">STEP.03</span>質問内容作成
                    </dt>
                    <dd className="text-xs">
                      ユーザー様が聞きたいご質問を作成して「完了」を押します。
                    </dd>
                    <dd className="text-xs">
                      アドバイザーが答えられる質問リストの中で選びます。
                    </dd>
                  </dl>
                </li>

                <li>
                  <dl>
                    <dt>
                      <span className="icon">STEP.04</span>マッチング成立
                    </dt>
                    <dd className="text-xs">
                      プッシュメッセージを確認します。
                    </dd>
                    <dd className="text-xs">
                      トークルーム予約の確認をします。
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <span className="icon">STEP.05</span>DanGoTalk開始
                    </dt>
                    <dd className="text-xs">トークルームに入場します。</dd>
                    <dd className="text-xs">トークをお楽しんでください！</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Step;
