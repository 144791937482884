import React, { FunctionComponent, useEffect } from "react";
import Navbar from "../components/Navbars/BlackNavbar";
import Footer from "../components/Footers/Footer";
import ContactFormComponent from "../components/ContactForm/ContactFormComponent";

const Contact: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <main>
        <section className="pb-40 relative block bg-white">
          <ContactFormComponent></ContactFormComponent>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Contact;
