import type { ComponentType } from "react";
import {
  Home,
  Notice,
  NoticeInfo,
  Step,
  Faq,
  Contact,
  Sent,
  Privacy,
  Conditions,
  Commerce,
  Cancellation,
} from "../pages";

export type Route = Readonly<{
  path: string;
  name: string;
  Component: ComponentType;
}>;
export const routes: Route[] = [
  {
    path: "/",
    name: "Home",
    Component: Home,
  },
  {
    path: "/notice",
    name: "Notice",
    Component: Notice,
  },
  {
    path: "/notice/:title",
    name: "NoticeInfo",
    Component: NoticeInfo,
  },
  {
    path: "/faq",
    name: "Faq",
    Component: Faq,
  },
  {
    path: "/step",
    name: "Step",
    Component: Step,
  },
  {
    path: "/contact",
    name: "Contact",
    Component: Contact,
  },
  {
    path: "/contact/complete",
    name: "Sent",
    Component: Sent,
  },
  {
    path: "/privacy",
    name: "Privacy",
    Component: Privacy,
  },
  {
    path: "/conditions",
    name: "Conditions",
    Component: Conditions,
  },
  {
    path: "/commerce",
    name: "Commerce",
    Component: Commerce,
  },
  {
    path: "/cancellation",
    name: "Cancellation",
    Component: Cancellation,
  },
];
