import { FunctionComponent, useEffect } from "react";
import "../assets/styles/list.css";

const Conditions: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto my-6 p-3">
        <div>
          <span className="text-xl md:text-2xl lg:text-3xl font-semibold mt-4">
            DanGoTalkユーザー利用規約
          </span>
          <div className="mt-5">
            <span className="text-start text-xs md:text-sm lg:text-md mx-auto">
              本DanGoTalkユーザー利用規約（以下、「本規約」といいます）は、
              株式会社ｄａＯｎ（以下、「当社」といいます）が提供・運営するインターネットサービス
              「DanGoTalk」（以下、「本サービス」といいます）の利用条件を定めるものです。
            </span>
          </div>
          <ul>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">第1条（適用）</span>
              <ol className="order-list">
                <li className="lists">
                  本規約は、本サービスの利用に関する当社とユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、本サービスの利用に関わる一切の関係に適用されるものとします。
                </li>
                <li className="lists">
                  本サービスはユーザー（第2条に定義）としての利用者とアドバイザ（第2条に定義）として利用者があります。本規約はユーザーに適用されるものとします。
                </li>
                <li className="lists">
                  当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
                </li>
                <li className="lists">
                  本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md  mt-5">
              <span className="font-semibold pl-4 pt-2">第2条（定義）</span>

              <ol className="order-list mt-4">
                <li className="lists">
                  「利用者」とは、ユーザーとアドバイザーを含め本サービスの利用者としての登録がなされた個人又は法人を意味します。
                </li>
                <li className="lists">
                  「ユーザー」とは、本サービスに掲載されているアドバイスを受けることを目的として利用している利用者を意味します。
                </li>
                <li className="lists">
                  「アドバイザー」とは、自らが持つ知見や情報を元にアドバイスを提供する利用者を意味します。
                </li>
                <li className="lists">
                  「アドバイス料」とは、第9条3項に定義する「アドバイス料」を意味するものとします。
                </li>
                <li className="lists">
                  「手数料」とは、アドバイザーが本サービスの対価として当社に支払う手数料を意味するものとします。
                </li>
                <li className="lists">
                  「キャンセル料」とは、第11条1項に定義する「キャンセル料」を意味するものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md mt-5">
              <span className="font-semibold pl-4 pt-2">
                第3条（規約への同意）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、本規約の定めに従って本サービスを利用しなければなりません。ユーザーは、
                  当社の指定する方法に従い本規約に有効かつ取消不能な同意をしないかぎり本サービスを利用できません。
                </li>
                <li className="lists">
                  ユーザーは、本サービスを実際に利用することによって本規約に有効かつ取消不能な同意をしたものとみなされます。
                </li>
                <li className="lists">
                  本サービスにおいて本規約とは別に適用される個別利用規約がある場合、ユーザーは、
                  本規約のほか個別利用規約の定めにも従って本サービスを利用しなければなりません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md mt-5">
              <span className="font-semibold pl-4 pt-2">
                第4条（ユーザーの登録）
              </span>

              <ol className="order-list mt-4">
                <li className="lists">
                  ユーザーは、本サービスの利用に際してユーザーご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。
                </li>
                <li className="lists">
                  当社の承諾なく、同一の人物が本サービスに関して複数の同一内容のアカウントを持つことはできません。
                </li>
                <li className="lists">
                  ユーザーに関する情報を登録する場合、真実、正確かつ完全な情報を提供し、常に最新の情報となるよう修正しなければならず、また、ユーザーとしてこれに協力させるよう努めるものとします。
                </li>
                <li className="lists">
                  ユーザーは、本サービスの利用に際してパスワードを登録する場合、これを不正に利用されないようご自身の責任で厳重に管理しなければなりません。当社は、登録されたパスワードを利用して行なわれた一切の行為を、ユーザーご本人の行為とみなすことができます。
                </li>
                <li className="lists">
                  本サービスに登録したユーザーは、いつでもアカウントを削除して退会することができます。
                </li>
                <li className="lists">
                  本規約に基づくユーザーの権利義務はユーザーの一身に専属し、ユーザーが死亡した場合、アカウントは停止の後、削除します。
                </li>
                <li className="lists">
                  当社は、ユーザーが本規約に違反しまたは違反するおそれがあると認めた場合、あらかじめユーザーに通知することなく、アカウントを停止または削除することができます。
                </li>
                <li className="lists">
                  当社は、ユーザー、その身元引受人またはご家族、その他関係者が、当社に対して不当な問い合わせまたは要求をする行為、その他他者の本サービスの利用を妨害し支障を与えるまたはそのおそれのある行為を行った場合、当該ユーザーのアカウントを停止または削除することができます。
                </li>
                <li className="lists">
                  ユーザーの本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。ユーザーが誤ってアカウントを削除した場合であっても、アカウントの復旧はできませんのでご注意ください。
                </li>
                <li className="lists">
                  本サービスのアカウントは、ユーザーに一身専属的に帰属します。ユーザーの本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md ">
              <span className="font-semibold pl-4 pt-2">
                第5条（ユーザーの登録情報の変更）
              </span>
              <div>
                <span>
                  ユーザーは、自己の登録情報に変更があった場合には、遅滞なく、会員用の管理画面を介して、
                  登録情報の変更を当社へ通知するとともに、登録情報の変更にかかる当社が請求する資料を求められた場合には提出するものとします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第6条（IDおよびパスワードの管理）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、自己の責任において、本サービスのIDおよびパスワードを適切に管理するものとします。
                </li>
                <li className="lists">
                  ユーザーは、いかなる場合にも、IDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、IDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのIDを登録しているユーザーご自身による利用とみなします。
                </li>
                <li className="lists">
                  ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負わないものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第7条（ユーザーの責任及び義務）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、ユーザーご自身の責任において本サービスを利用するものとし、本サービスにおいてユーザーが行った一切の行為およびその結果について一切の責任を負うものとします。
                </li>
                <li className="lists">
                  当社は、ユーザーが本規約に違反して本サービスを利用していると認めた場合、当社が必要かつ適切と判断する措置を講じます。ただし、当社は、かかる違反を防止または是正する義務を負いません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第8条（ユーザーの退会）
              </span>
              <div>
                <span>
                  ユーザーは、当社の定める退会手続により、本サービスから退会できるものとします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第9条（アドバイスの予約）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、当社所定の方法により、アドバイザーへアドバイスを予約することができます。ユーザーからの予約をアドバイザーが承諾することで予約手続きが完了するものとします。
                </li>
                <li className="lists">
                  前項の予約手続が完了した場合、ユーザーに対し予約完了の通知が行われるものとし、当該通知がユーザーに到達した時点で、アドバイザーとユーザーの間にユーザーが予約したアドバイスに関する契約（以下「アドバイス契約」といいます）が成立するものとします。但し、ユーザーの責に帰すべき事由により当該通知が到達しなかった場合には、通常到達すべき時点において当該通知が到達したものとみなしてアドバイス契約が成立するものとします。
                </li>
                <li className="lists">
                  ユーザーは、前項の通知の定めるところに従い、前項に基づきアドバイス契約が成立したアドバイスの対価（以下「アドバイス料」といいます）を当社の指定する方法で当社に支払うものとします。振込手数料その他支払に必要な費用はユーザーの負担とします。
                </li>
                <li className="lists">
                  ユーザーがアドバイス料の支払を遅滞した場合、ユーザーは年14.6％の割合による遅延損害金を当社に支払うものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第10条（アドバイス料の支払）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、当社に対し、アドバイス料を支払います。当社は、当社所定の本サービスの手数料を控除したうえで、残額をアドバイザーに支払います。
                </li>
                <li className="lists">
                  支払方法は、クレジットカード決済のみで、クレジットカードの決済手段の利用については、ユーザーと当該カード会社等との契約条件や利用規約に従うものとします。
                </li>
                <li className="lists">
                  クレジットカードによる決済ができなかった場合、当社は依頼者による本サービスの利用を終了させることができるものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第11条（キャンセル等）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、第9条1項に定められた予約手続が完了時から実施日時の48時間前までは無料でキャンセルすることが可能である。
                  24時間以上48時間未満までにキャンセルする場合は、アドバイス料の50%、24時間未満にキャンセルする場合は、
                  アドバイス料の100%をキャンセル料として支払うものとします。
                </li>
                <li className="lists">
                  前項に基づきキャンセル料が発生する場合、ユーザーは、当社に対し、キャンセルが確定した時点において、
                  前項に定められたキャンセル料を事前に登録したクレジットカードで支払うものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第12条（禁止事項）
              </span>

              <ol className="order-list">
                <li className="lists">
                  ユーザーは、本サービスの利用において、以下の各号のいずれかの行為をしてはなりません。
                  <ol className="order-list">
                    <li className="ol-in-list">
                      法令または公序良俗に違反する行為
                    </li>
                    <li className="ol-in-list">犯罪行為に関連する行為</li>
                    <li className="ol-in-list">
                      本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
                    </li>
                    <li className="ol-in-list">
                      当社、他の利用者（ユーザー、アドバイザー）及び第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                    </li>
                    <li className="ol-in-list">
                      当社、他の利用者（ユーザー、アドバイザー）、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                    </li>
                    <li className="ol-in-list">
                      本サービスによって得られた情報を商業的に利用する行為
                    </li>
                    <li className="ol-in-list">
                      当社のサービスの運営を妨害するおそれのある行為
                    </li>
                    <li className="ol-in-list">
                      不正アクセスをし、またはこれを試みる行為
                    </li>
                    <li className="ol-in-list">
                      他の利用者（ユーザー、アドバイザー）に関する個人情報等を収集または蓄積する行為
                    </li>
                    <li className="ol-in-list">
                      不正な目的を持って本サービスを利用する行為
                    </li>
                    <li className="ol-in-list">
                      本サービスの他の利用者（ユーザー、アドバイザー）またはその他の第三者に不利益、損害、不快感を与える行為
                    </li>
                    <li className="ol-in-list">
                      他の利用者（ユーザー、アドバイザー）に成りすます行為
                    </li>
                    <li className="ol-in-list">
                      当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                    </li>
                    <li className="ol-in-list">
                      異性との出会いを目的とした行為
                    </li>
                    <li className="ol-in-list">
                      当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
                    </li>
                    <li className="ol-in-list">
                      人種、民族、信条、性別、社会的身分、居住場所、身体的特徴、病歴、教育、財産及び収入等を根拠にする差別的表現行為
                    </li>
                    <li className="ol-in-list">
                      倫理的に問題がある低俗、有害、下品な行為、他人に嫌悪感を与える内容の情報を開示する行為
                    </li>
                    <li className="ol-in-list">
                      ポルノ、売春、風俗営業、これらに関連する内容の情報を開示する行為
                    </li>
                    <li className="ol-in-list">
                      迷惑行為、嫌がらせ行為、誹謗中傷行為、その他当社、又は他の利用者（ユーザー、アドバイザー）、外部SNS事業者その他の第三者に精神的被害・経済的被害を与える可能性のある行為
                    </li>
                    <li className="ol-in-list">
                      手段に関わらず、ユーザーとアドバイザーの間での直接の支払い及び取引は禁止します。
                    </li>
                    <li className="ol-in-list">
                      その他、当社が不適切と判断する行為
                    </li>
                  </ol>
                </li>
                <li className="lists">
                  当社は、本サービスにおけるユーザーによる情報の送信行為が前項各号のいずれかに該当し、
                  又は該当するおそれがあると当社が判断した場合には、ユーザーに事前に通知することなく、
                  当該情報の全部又は一部を削除及び利用停止することができるものとします。
                  当社は、本項に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
                </li>
                <li className="lists">
                  ユーザーは当社が犯罪行為の防止、ユーザーの保護、健全なサービスを運営する目的で、
                  本サービス上でのユーザー及びアドバイザー間のメッセージ内容を閲覧、削除することに同意するものとします。
                  ただし、当社としてメッセージ内容の監視、巡回する義務を負うものではありません。
                  また、当社はメッセージ内容の監視、巡回した内容につき当該対応の結果について、
                  対応の内容及び理由をユーザーに対して返答する義務を負わず、当該対応の結果、ユーザーに生じた損害について一切の責任を負いません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第13条（本サービスの提供の停止等）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                  <ol className="order-list">
                    <li className="ol-in-list">
                      本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                    </li>
                    <li className="ol-in-list">
                      地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                    </li>
                    <li className="ol-in-list">
                      コンピュータまたは通信回線等が事故により停止した場合
                    </li>
                    <li className="ol-in-list">
                      外部サービスのトラブルやサービスの中断又は停止の影響により、本サービスとの連携の停止及び仕様変更等が生じた場合
                    </li>
                    <li className="ol-in-list">
                      その他、当社が本サービスの提供が困難と判断した場合
                    </li>
                  </ol>
                </li>
                <li className="lists">
                  当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社はユーザーに事前に通知するものとします。
                </li>
                <li className="lists">
                  当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第14条（利用制限および登録抹消）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、
                  本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
                  <ol className="order-list">
                    <li className="ol-in-list">
                      本規約のいずれかの条項に違反した場合
                    </li>
                    <li className="ol-in-list">
                      登録事項に虚偽の事実があることが判明した場合
                    </li>
                    <li className="ol-in-list">
                      本サービスを最終の利用から1年以上利用がなく、当社からの連絡に対して、返答がない場合
                    </li>
                    <li className="ol-in-list">
                      当社、他の利用者（ユーザー、アドバイザー）及び第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
                    </li>
                    <li className="ol-in-list">
                      手段の如何を問わず、本サービスの運営を妨害した場合
                    </li>
                    <li className="ol-in-list">
                      支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                    </li>
                    <li className="ol-in-list">
                      自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けた場合
                    </li>
                    <li className="ol-in-list">
                      差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
                    </li>
                    <li className="ol-in-list">
                      租税公課の滞納処分を受けた場合
                    </li>
                    <li className="ol-in-list">
                      死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
                    </li>
                    <li className="ol-in-list">
                      その他、当社が本サービスの利用を適当でないと判断した場合
                    </li>
                  </ol>
                </li>
                <li className="lists">
                  前項各号のいずれかの事由に該当した場合、ユーザーは当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                </li>
                <li className="lists">
                  当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第15条（保証の否認および免責事項）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます）がないことを明示的にも黙示的にも保証しておりません。さらに、ユーザーが当社から直接又は間接に本サービス又は他のユーザーに関する情報を得た場合であっても、当社はユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
                </li>
                <li className="lists">
                  当社は、本サービスに起因してユーザーとアドバイザーの間に生じたあらゆる損害について一切の責任を負わないものとします。アドバイス等の提供又はアドバイスを受ける事に関しては、ユーザーやアドバイザー自身が責任を負うものとし、当社はこれらにつき一切の責任を負わないものとします。
                </li>
                <li className="lists">
                  当社は、当社の過失（重過失を除きます）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます）について一切の責任を負いません。
                </li>
                <li className="lists">
                  当社は、本サービスに関して、ユーザーと他の利用者（ユーザー、アドバイザー）または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                </li>
                <li className="lists">
                  当社は、ユーザーとの契約が終了した場合、当該ユーザーが掲載していた情報の一部又は全部について削除又は改変する場合があり、当該ユーザーの情報（ユーザーが掲載した記事等の情報を含みます）に関連してユーザーが本サービスに掲載した情報の一部又は全部を削除又は改変する場合があります。
                </li>
                <li className="lists">
                  本サービスから他のウェブサイトへのリンクや、第三者から本サービスへのリンクが設定される場合、当社は本サービス以外のウェブサイト及びそれにより得られる情報に関して、理由の如何を問わず、一切の責任を負わないものとします。
                </li>
                <li className="lists">
                  当社は、当社サービスにおけるSNSとの連携機能を利用した際に、SNSに表示される情報に関して、理由の如何を問わず、一切の責任を負わないものとします。
                </li>
                <li className="lists">
                  本サービスが外部SNSサービスと連携している場合において、ユーザーは外部SNS利用規約を自己の費用と責任で遵守するものとし、ユーザーと当該外部SNSサービスを運営する外部SNS事業者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。
                </li>
                <li className="lists">
                  当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、ユーザーのメッセージ又は情報の削除又は消失､ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してユーザーが被った損害につき、賠償する責任を一切負わないものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第16条（サービス内容の変更等）
              </span>
              <div>
                <span>
                  当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、
                  これによってユーザーに生じた損害について一切の責任を負いません。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第17条（利用規約の変更）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、本サービスの内容を自由に変更できるものとします。
                </li>
                <li className="lists">
                  当社は、本規約（本サービスに関するルール、諸規定等を含みます。以下本項において同じ）を変更できるものとします。
                  当社は、本規約を変更した場合には、ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、
                  ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、
                  ユーザーは、本規約の変更に同意したものとみなします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第18条（個人情報の取扱い）
              </span>
              <div>
                <span>
                  当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第19条（通知または連絡）
              </span>
              <div>
                <span>
                  ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
                  当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、
                  現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第20条（権利義務の譲渡の禁止）
              </span>
              <div>
                <span>
                  ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、
                  第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第21条（準拠法・裁判管轄）
              </span>

              <ol className="order-list">
                <li className="lists">
                  本規約の解釈にあたっては、日本法を準拠法とします。
                </li>
                <li className="lists">
                  本規約及び本サービスに関して紛争が生じた場合には、東京地方裁判所を専属的合意管轄とします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第22条（協議解決）
              </span>
              <div>
                <span>
                  当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、
                  互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
                </span>
              </div>
            </li>
          </ul>
          <br />
          <br />
          以上
          <span className="text-start text-xs md:text-sm lg:text-md pl-4 pt-2">
            DanGoTalkアドバイザー利用規約
          </span>
          <div className="text-start text-xs md:text-sm lg:text-md">
            <span>
              本DanGoTalkアドバイザー利用規約（以下、「本規約」といいます）は、株式会社ｄａＯｎ
              （以下、「当社」といいます）が提供・運営するインターネットサービス「DanGoTalk」
              （以下、「本サービス」といいます）の利用条件を定めるものです。
            </span>
          </div>
          <ul>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">第1条（適用）</span>

              <ol className="order-list">
                <li className="lists">
                  本規約は、本サービスの利用に関する当社とアドバイザー（第2条に定義）との間の権利義務関係を定めることを目的とし、本サービスの利用に関わる一切の関係に適用されるものとします。
                </li>
                <li className="lists">
                  本サービスはユーザー（第2条に定義）としての利用者とアドバイザー（第2条に定義）として利用者があります。本規約はアドバイザーに適用されるものとします。
                </li>
                <li className="lists">
                  当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
                </li>
                <li className="lists">
                  本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">第2条（定義）</span>

              <ol className="order-list">
                <li className="lists">
                  「利用者」とは、ユーザーとアドバイザーを含め本サービスの利用者としての登録がなされた個人又は法人を意味します。
                </li>
                <li className="lists">
                  「ユーザー」とは、本サービスに掲載されているアドバイスを受けることを目的として利用している利用者を意味します。
                </li>
                <li className="lists">
                  「アドバイザー」とは、自らが持つ知見や情報を元にアドバイスを提供する利用者を意味します。
                </li>
                <li className="lists">
                  「アドバイスページ」とは、アドバイザー自身が持つ知見や情報を元にアドバイス可能な内容をまとめたページを意味します。
                </li>
                <li className="lists">
                  「アドバイザー報酬」とは、アドバイス料から手数料を差し引いた金額を意味します。
                </li>
                <li className="lists">
                  「アドバイス料」とは、第11条2項に従い、アドバイス契約が成立した際に発生するアドバイスの対価を意味するものとします。
                </li>
                <li className="lists">
                  「手数料」とは、アドバイザーが本サービスの対価として当社に支払う手数料を意味するものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第3条（規約への同意）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、本規約の定めに従って本サービスを利用しなければなりません。
                  アドバイザーは、
                  当社の指定する方法に従い本規約に有効かつ取消不能な同意をしないかぎり本サービスを利用できません。
                </li>
                <li className="lists">
                  アドバイザーは、本サービスを実際に利用することによって本規約に有効かつ取消不能な同意をしたものとみなされます。
                </li>
                <li className="lists">
                  本サービスにおいて本規約とは別に適用される個別利用規約がある場合、
                  アドバイザーは、本規約のほか個別利用規約の定めにも従って本サービスを利用しなければなりません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第4条（サービスの内容）
              </span>

              <ol className="order-list">
                <li className="lists">
                  本サービスは、知見や情報を必要とするユーザーと知見や情報を持つアドバイザーをマッチングさせて、オンライン上でアドバイスを提供できるシェアリング・プラットフォームの提供を行います。
                </li>
                <li className="lists">
                  本サービスは、ユーザーとアドバイザーが直接にアドバイス契約を締結することを目的とするものであり、当社は本契約の当事者とはなりません。但し、決済については、当社が代行します。
                </li>
                <li className="lists">
                  ユーザーとアドバイザーが本サービスを利用してアドバイス契約を締結する場合、アドバイス内容及び遂行方法に関わらず、雇用関係のあっせんを行うものではありません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第5条（アドバイザーの登録）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、本サービスの利用に際してアドバイザーご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。
                </li>
                <li className="lists">
                  当社の承諾なく、同一の人物が本サービスに関して複数の同一内容のアカウントを持つことはできません。
                </li>
                <li className="lists">
                  アドバイザーに関する情報を登録する場合、真実、正確かつ完全な情報を提供し、常に最新の情報となるよう修正しなければならず、また、アドバイザーとしてこれに協力させるよう努めるものとします。
                </li>
                <li className="lists">
                  アドバイザーは、本サービスの利用に際してパスワードを登録する場合、これを不正に利用されないようご自身の責任で厳重に管理しなければなりません。当社は、登録されたパスワードを利用して行なわれた一切の行為を、アドバイザーご本人の行為とみなすことができます。
                </li>
                <li className="lists">
                  本サービスに登録したアドバイザーは、いつでもアカウントを削除して退会することができます。
                </li>
                <li className="lists">
                  本規約に基づくアドバイザーの権利義務はアドバイザーの一身に専属し、アドバイザーが死亡した場合、アカウントは停止の後、削除します。
                </li>
                <li className="lists">
                  当社は、アドバイザーが本規約に違反しまたは違反するおそれがあると認めた場合、あらかじめアドバイザーに通知することなく、アカウントを停止または削除することができます。
                </li>
                <li className="lists">
                  当社は、アドバイザー、その身元引受人またはご家族、その他関係者が、当社に対して不当な問い合わせまたは要求をする行為、その他他者の本サービスの利用を妨害し支障を与えるまたはそのおそれのある行為を行った場合、当該アドバイザーのアカウントを停止または削除することができます。
                </li>
                <li className="lists">
                  アドバイザーの本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。アドバイザーが誤ってアカウントを削除した場合であっても、アカウントの復旧はできませんのでご注意ください。
                </li>
                <li className="lists">
                  本サービスのアカウントは、アドバイザーに一身専属的に帰属します。アドバイザーの本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第6条（アドバイザーの登録情報の変更）
              </span>
              <div>
                <span>
                  アドバイザーは、自己の登録情報に変更があった場合には、
                  遅滞なく、会員用の管理画面を介して、登録情報の変更を当社へ通知するとともに、
                  登録情報の変更にかかる当社が請求する資料を求められた場合には提出するものとします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第7条（IDおよびパスワードの管理）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、自己の責任において、本サービスのIDおよびパスワードを適切に管理するものとします。
                </li>
                <li className="lists">
                  アドバイザーは、いかなる場合にも、IDおよびパスワードを第三者に譲渡または貸与し、
                  もしくは第三者と共用することはできません。
                  当社は、IDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのIDを登録しているアドバイザーご自身による利用とみなします。
                </li>
                <li className="lists">
                  ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録アドバイザーが負うものとし、
                  当社は一切の責任を負わないものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第8条（アドバイザーの責任及び義務）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、アドバイザーご自身の責任において本サービスを利用するものとし、
                  本サービスにおいてアドバイザーが行った一切の行為およびその結果について一切の責任を負うものとします。
                </li>
                <li className="lists">
                  当社は、アドバイザーが本規約に違反して本サービスを利用していると認めた場合、
                  当社が必要かつ適切と判断する措置を講じます。ただし、当社は、かかる違反を防止または是正する義務を負いません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第9条（アドバイザーの退会）
              </span>
              <div>
                <span>
                  アドバイザーは、当社の定める退会手続により、本サービスから退会できるものとします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第10条（アドバイスページ作成）
              </span>

              <ol className="order-list">
                <li>
                  アドバイザーは、アドバイスページ（第2条に定義）を作成する。ユーザーはアドバイスページを閲覧し、アドバイザーへアドバイスの予約ができる。
                  <ol className="order-list">
                    <li className="ol-in-list">アドバイス可能内容</li>
                    <li className="ol-in-list">アドバイス料</li>
                    <li className="ol-in-list">アドバイス可能日時</li>
                  </ol>
                </li>
                <li className="lists">
                  アドバイスページには以下の情報をユーザーに提供する。
                </li>
                <li className="lists">
                  アドバイス料の設定は10分単位で、200円から100万円未満まで設定できるものとします。
                </li>
                <li className="lists">
                  ユーザーからのアドバイス依頼で、アドバイザーはアドバイス可能なのか否かを判断し、アドバイス可能な場合にアドバイス予約を承諾することで、予約手続きが完了するものとします。
                </li>
                <li className="lists">
                  アドバイスページ作成の禁止事項
                  <ol className="order-list">
                    <li className="ol-in-list">
                      広告媒体として使用する行為
                      （外部サイトへの誘導、アドバイス料の振込先記載・メール・電話の直接予約申込など弊社サービスを経由しないアドバイス料の支払いへの誘導など）
                    </li>
                    <li className="ol-in-list">虚偽の記載</li>
                    <li className="ol-in-list">
                      本サービスの手数料支払いを意図的に回避するような記載
                    </li>
                    <li className="ol-in-list">
                      アドバイザーのメールアドレスやLINE（公式LINEアカウントを含む）、SNSなどの連絡先の記載
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第11条（アドバイスの提供）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、当社の定めるところに従い、アドバイスページを作成することができるものとします。
                </li>
                <li className="lists">
                  前項のアドバイスページの作成後、第12条1項に従って、予約手続が完了した場合には、アドバイザーはユーザーに対し、ユーザーが予約した質問の内容に従ってアドバイスを提供する義務を負うものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第12条（アドバイスの承諾）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、ユーザーからのアドバイス予約を承諾することで予約手続きが完了するものとします。
                </li>
                <li className="lists">
                  前項の予約手続が完了した場合、アドバイザーに対し予約完了の通知が行われるものとし、
                  当該通知がアドバイザーに到達した時点で、アドバイザーとユーザーの間にユーザーが予約したアドバイスに関する契約
                  （以下「アドバイス契約」といいます）が成立するものとします。
                  但し、アドバイザーの責に帰すべき事由により当該通知が到達しなかった場合には、
                  通常到達すべき時点において当該通知が到達したものとみなしてアドバイス契約が成立するものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第13条（アドバイザー報酬などの支払）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、当社に対し、ユーザーからのアドバイス料（第2条に定義）を代理受領する権限を付与するものとします。当社は、当社所定の本サービスの手数料を控除した金額をアドバイザー報酬としてアドバイザーに支払います。
                </li>
                <li className="lists">
                  アドバイザー報酬の支払方法は、銀行振込のみで、振込手数料はアドバイザーの負担とします。また、支払時期は当社の定めるところに従い、アドバイザーが振込申請を行います。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第14条（アドバイザーのキャンセル等）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、第12条1項に定められた予約手続が完了時からは原則として、キャンセル出来ないものとします。ただし、やむを得ない事由がある場合を除きます。
                </li>
                <li className="lists">
                  無断キャンセルや実施日時まで48時間未満のキャンセルが続く場合は、本サービスの利用権利を剥奪する可能性がありますのでご留意ください。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第15条（禁止事項）
              </span>

              <ol className="order-list">
                <li className="lists">
                  アドバイザーは、本サービスの利用において、以下の各号のいずれかの行為をしてはなりません。
                  <ol className="order-list">
                    <li className="ol-in-list">
                      法令または公序良俗に違反する行為
                    </li>
                    <li className="ol-in-list">犯罪行為に関連する行為</li>
                    <li className="ol-in-list">
                      本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
                    </li>
                    <li className="ol-in-list">
                      当社、他の利用者（ユーザー、アドバイザー）及び第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                    </li>
                    <li className="ol-in-list">
                      当社、他の利用者（ユーザー、アドバイザー）、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                    </li>
                    <li className="ol-in-list">
                      本サービスによって得られた情報を商業的に利用する行為
                    </li>
                    <li className="ol-in-list">
                      当社のサービスの運営を妨害するおそれのある行為
                    </li>
                    <li className="ol-in-list">
                      不正アクセスをし、またはこれを試みる行為
                    </li>
                    <li className="ol-in-list">
                      他の利用者（ユーザー、アドバイザー）に関する個人情報等を収集または蓄積する行為
                    </li>
                    <li className="ol-in-list">
                      不正な目的を持って本サービスを利用する行為
                    </li>
                    <li className="ol-in-list">
                      本サービスの他の利用者（ユーザー、アドバイザー）またはその他の第三者に不利益、損害、不快感を与える行為
                    </li>
                    <li className="ol-in-list">
                      他の利用者（ユーザー、アドバイザー）に成りすます行為
                    </li>
                    <li className="ol-in-list">
                      当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                    </li>
                    <li className="ol-in-list">
                      異性との出会いを目的とした行為
                    </li>
                    <li className="ol-in-list">
                      当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
                    </li>
                    <li className="ol-in-list">
                      人種、民族、信条、性別、社会的身分、居住場所、身体的特徴、病歴、教育、財産及び収入等を根拠にする差別的表現行為
                    </li>
                    <li className="ol-in-list">
                      倫理的に問題がある低俗、有害、下品な行為、他人に嫌悪感を与える内容の情報を開示する行為
                    </li>
                    <li className="ol-in-list">
                      ポルノ、売春、風俗営業、これらに関連する内容の情報を開示する行為
                    </li>
                    <li className="ol-in-list">
                      迷惑行為、嫌がらせ行為、誹謗中傷行為、その他当社、又は他の利用者（ユーザー、アドバイザー）、外部SNS事業者その他の第三者に精神的被害・経済的被害を与える可能性のある行為
                    </li>
                    <li className="ol-in-list">
                      手段に関わらず、ユーザーとアドバイザーの間での直接の支払い及び取引は禁止します。
                    </li>
                    <li className="ol-in-list">
                      その他、当社が不適切と判断する行為
                    </li>
                  </ol>
                </li>
                <li className="lists">
                  当社は、本サービスにおけるアドバイザーによる情報の送信行為が前項各号のいずれかに該当し、
                  又は該当するおそれがあると当社が判断した場合には、アドバイザーに事前に通知することなく、
                  当該情報の全部又は一部を削除及び利用停止することができるものとします。
                  当社は、本項に基づき当社が行った措置に基づき登録アドバイザーに生じた損害について一切の責任を負いません。
                </li>
                <li className="lists">
                  アドバイザーは当社が犯罪行為の防止、アドバイザーの保護、健全なサービスを運営する目的で、
                  本サービス上での利用者（ユーザー、アドバイザー）間のメッセージ内容を閲覧、削除することに同意するものとします。
                  ただし、当社としてメッセージ内容の監視、巡回する義務を負うものではありません。
                  また、当社はメッセージ内容の監視、巡回した内容につき当該対応の結果について、
                  対応の内容及び理由をアドバイザーに対して返答する義務を負わず、当該対応の結果、アドバイザーに生じた損害について一切の責任を負いません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第16条（本サービスの提供の停止等）
              </span>

              <ol className="order-list">
                <li>
                  当社は、以下のいずれかの事由があると判断した場合、アドバイザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                  <ol className="order-list">
                    <li className="ol-in-list">
                      本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                    </li>
                    <li className="ol-in-list">
                      地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                    </li>
                    <li className="ol-in-list">
                      コンピュータまたは通信回線等が事故により停止した場合
                    </li>
                    <li className="ol-in-list">
                      外部サービスのトラブルやサービスの中断又は停止の影響により、本サービスとの連携の停止及び仕様変更等が生じた場合
                    </li>
                    <li className="ol-in-list">
                      その他、当社が本サービスの提供が困難と判断した場合
                    </li>
                  </ol>
                </li>
                <li className="lists">
                  当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社はアドバイザーに事前に通知するものとします。
                </li>
                <li className="lists">
                  当社は、本サービスの提供の停止または中断により、アドバイザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第17条（利用制限および登録抹消）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、アドバイザーが以下のいずれかに該当する場合には、事前の通知なく、アドバイザーに対して、本サービスの全部もしくは一部の利用を制限し、またはアドバイザーとしての登録を抹消することができるものとします。
                  <ol className="order-list">
                    <li className="ol-in-list">
                      本規約のいずれかの条項に違反した場合
                    </li>
                    <li className="ol-in-list">
                      登録事項に虚偽の事実があることが判明した場合
                    </li>
                    <li className="ol-in-list">
                      本サービスを最終の利用から1年以上利用がなく、当社からの連絡に対して、返答がない場合
                    </li>
                    <li className="ol-in-list">
                      当社、他の利用者（ユーザー、アドバイザー）及び第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
                    </li>
                    <li className="ol-in-list">
                      手段の如何を問わず、本サービスの運営を妨害した場合
                    </li>
                    <li className="ol-in-list">
                      支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                    </li>
                    <li className="ol-in-list">
                      自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けた場合
                    </li>
                    <li className="ol-in-list">
                      差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
                    </li>
                    <li className="ol-in-list">
                      租税公課の滞納処分を受けた場合
                    </li>
                    <li className="ol-in-list">
                      死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
                    </li>
                    <li className="ol-in-list">
                      その他、当社が本サービスの利用を適当でないと判断した場合
                    </li>
                  </ol>
                </li>
                <li className="lists">
                  前項各号のいずれかの事由に該当した場合、アドバイザーは当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                </li>
                <li className="lists">
                  当社は、本条に基づき当社が行った行為によりアドバイザーに生じた損害について、一切の責任を負いません。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第18条（保証の否認および免責事項）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます）がないことを明示的にも黙示的にも保証しておりません。さらに、アドバイザーが当社から直接又は間接に本サービス又は他のアドバイザーに関する情報を得た場合であっても、当社はアドバイザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
                </li>
                <li className="lists">
                  当社は、本サービスに起因して利用者（ユーザー、アドバイザー）の間に生じたあらゆる損害について一切の責任を負わないものとします。アドバイス等の提供又はアドバイスを受ける事に関しては、利用者（ユーザー、アドバイザー）自身が責任を負うものとし、当社はこれらにつき一切の責任を負わないものとします。
                </li>
                <li className="lists">
                  当社は、当社の過失（重過失を除きます）による債務不履行または不法行為によりアドバイザーに生じた損害のうち特別な事情から生じた損害（当社またはアドバイザーが損害発生につき予見し、または予見し得た場合を含みます）について一切の責任を負いません。
                </li>
                <li className="lists">
                  当社は、本サービスに関して、アドバイザーと他の利用者（ユーザー、アドバイザー）または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                </li>
                <li className="lists">
                  当社は、アドバイザーとの契約が終了した場合、当該アドバイザーが掲載していた情報の一部又は全部について削除又は改変する場合があり、当該アドバイザーの情報（アドバイザーが掲載した記事等の情報を含みます）に関連してアドバイザーが本サービスに掲載した情報の一部又は全部を削除又は改変する場合があります。
                </li>
                <li className="lists">
                  本サービスから他のウェブサイトへのリンクや、第三者から本サービスへのリンクが設定される場合、当社は本サービス以外のウェブサイト及びそれにより得られる情報に関して、理由の如何を問わず、一切の責任を負わないものとします。
                </li>
                <li className="lists">
                  当社は、当社サービスにおけるSNSとの連携機能を利用した際に、SNSに表示される情報に関して、理由の如何を問わず、一切の責任を負わないものとします。
                </li>
                <li className="lists">
                  本サービスが外部SNSサービスと連携している場合において、アドバイザーは外部SNS利用規約を自己の費用と責任で遵守するものとし、アドバイザーと当該外部SNSサービスを運営する外部SNS事業者との間で紛争等が生じた場合でも、当社は当該紛争等について一切の責任を負いません。
                </li>
                <li className="lists">
                  当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、アドバイザーのメッセージ又は情報の削除又は消失､アドバイザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してアドバイザーが被った損害につき、賠償する責任を一切負わないものとします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第19条（サービス内容の変更等）
              </span>
              <div>
                <span>
                  当社は、アドバイザーに通知することなく、
                  本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってアドバイザーに生じた損害について一切の責任を負いません。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第20条（利用規約の変更）
              </span>

              <ol className="order-list">
                <li className="lists">
                  当社は、本サービスの内容を自由に変更できるものとします。
                </li>
                <li className="lists">
                  当社は、本規約（本サービスに関するルール、諸規定等を含みます。以下本項において同じ）を変更できるものとします。
                  当社は、本規約を変更した場合には、アドバイザーに当該変更内容を通知するものとし、当該変更内容の通知後、
                  アドバイザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、
                  アドバイザーは、本規約の変更に同意したものとみなします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第21条（個人情報の取扱い）
              </span>
              当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第22条（通知または連絡）
              </span>
              <div>
                <span>
                  アドバイザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
                  当社は、アドバイザーから、当社が別途定める方式に従った変更届け出がない限り、
                  現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、
                  これらは、発信時にアドバイザーへ到達したものとみなします。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第23条（権利義務の譲渡の禁止）
              </span>
              <div>
                <span>
                  アドバイザーは、当社の書面による事前の承諾なく、
                  利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                </span>
              </div>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第24条（準拠法・裁判管轄）
              </span>

              <ol className="order-list">
                <li className="lists">
                  本規約の解釈にあたっては、日本法を準拠法とします。
                </li>
                <li className="lists">
                  本規約及び本サービスに関して紛争が生じた場合には、東京地方裁判所を専属的合意管轄とします。
                </li>
              </ol>
            </li>
            <li className="text-start text-xs md:text-sm lg:text-md">
              <span className="font-semibold pl-4 pt-2">
                第25条（協議解決）
              </span>
              <div>
                <span>
                  当社及びアドバイザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Conditions;
