import "../assets/styles/commerce.css";
import type { FunctionComponent } from "react";
import { useEffect } from "react";

const Commerce: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto my-6">
        <div className="text-center">
          <span className="text-xs md:text-md lg:text-lg font-semibold">
            特定商取引法に基づく表記
          </span>
        </div>
        <table
          style={{ borderCollapse: "collapse" }}
          className="mx-auto mt-5 text-start text-xs md:text-sm lg:text-md"
        >
          <thead>
            <tr>
              <th className="header">事業者</th>
              <td>株式会社 ｄａＯｎ</td>
            </tr>
            <tr>
              <th className="header">代表者</th>
              <td>黄 淳光</td>
            </tr>
            <tr>
              <th className="header">サービスHP</th>
              <td>
                <a href="https://dangotalk.com" className="text-lightBlue-600">
                  https://dangotalk.com
                </a>
              </td>
            </tr>
            <tr>
              <th className="header">所在地</th>
              <td>
                〒169－0072
                <br />
                東京都新宿区大久保２丁目７-１大久保フジビル７０７号
              </td>
            </tr>
            <tr>
              <th className="header">企業HP</th>
              <td>
                <a href="https://daon.co.jp" className="text-lightBlue-600">
                  https://daon.co.jp
                </a>
              </td>
            </tr>
            <tr>
              <th className="header">お問い合わせ先</th>
              <td>
                <a
                  href="https://dangotalk.com/contact"
                  className="text-lightBlue-600"
                >
                  お問い合わせへ
                </a>
              </td>
            </tr>
            <tr>
              <th className="header">販売価格</th>
              <td>サービス内容によって異なります。</td>
            </tr>
            <tr>
              <th className="header">お支払方法</th>
              <td>クレジットカード決済</td>
            </tr>
            <tr>
              <th className="header">支払い時期</th>
              <td>
                サービス後３時間以内に自動でクレジットカードが決済されます。
              </td>
            </tr>
            <tr>
              <th className="header">商品の提供時期</th>
              <td>サービス申し込み時、記載のとおり</td>
            </tr>
            <tr>
              <th className="header">商品代金以外に必要な費用</th>
              <td>
                ウェブサイトの閲覧に必要となるインターネット接続料金その他通信料金等
              </td>
            </tr>
            <tr>
              <th className="header">キャンセルについて</th>
              <td>
                キャンセルについてはキャンセルポリシーに規定あり (
                <a
                  href="https://dangotalk.com/cancellation"
                  className="text-lightBlue-600"
                >
                  キャンセルポリシー
                </a>
                )
                <br />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default Commerce;
