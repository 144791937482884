import type { FunctionComponent } from "react";
import type { SearchFaqsQuery } from "../../API";
import React, { useEffect, useState } from "react";
import { searchQuery } from "../../utils/useSearchable";
import * as queries from "../../graphql/queries";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const About: FunctionComponent = () => {
  const [aboutDanGoTalk, setAboutDanGoTalk] = useState<
    SearchFaqsQuery | undefined | any
  >();

  useEffect(() => {
    async function aboutCategory() {
      let variables = {
        filter: {
          typeName: { eq: "Faq" },
        },
      };
      const category = await searchQuery(
        queries.listCategoryMasters,
        variables
      );
      const idValue = category?.data?.listCategoryMasters?.items
        .filter((d: any) => d.category === "DanGoTalkについて")
        .map((c: any) => c.id);
      let variable = {
        filter: {
          and: [
            { faqCategoryId: { eq: idValue.toString() } },
            { isView: { eq: true } },
          ],
        },
      };
      const aboutDanGoTalk = async () => {
        const aboutDanGoTalkData = await searchQuery(
          queries.searchFaqs,
          variable
        );
        setAboutDanGoTalk(aboutDanGoTalkData.data);
      };
      await aboutDanGoTalk();
    }

    aboutCategory();
  }, []);
  return (
    <>
      <div className="flex flex-wrap mx-auto pb-5 lg:pb-20">
        <div className="w-full">
          <div className="text-left">
            <span className="text-lg md:text-2xl mb-3 text-blueGray-600 font-semibold">
              DanGoTalkについて
            </span>
            <Accordion allowZeroExpanded>
              {aboutDanGoTalk?.searchFaqs?.items?.map((f: any) => {
                return (
                  <>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{f.title}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="bg-blue-50">
                        <p
                          className="p-10"
                          dangerouslySetInnerHTML={{
                            __html: f.content,
                          }}
                        ></p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </>
                );
              })}{" "}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
