import "../assets/styles/cancel.css";
import type { FunctionComponent } from "react";
import { useEffect } from "react";

const Cancellation: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto my-6">
        <div className="text-center">
          <span className="text-xs md:text-md lg:text-lg font-semibold">
            キャンセルポリシー
          </span>
        </div>
        <table
          style={{ borderCollapse: "collapse" }}
          className="mx-auto mt-5 text-caption text-start text-xs md:text-sm lg:text-md"
        >
          <thead>
            <tr>
              <th colSpan={4}></th>
              <td
                className="font-weight-bold"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                ユーザー払い戻し
              </td>
            </tr>
            <tr>
              <th rowSpan={7}>予約キャンセル</th>
              <td rowSpan={4}>ユーザー</td>
              <td colSpan={2}>アドバイザー承諾前</td>
              <td>100%</td>
            </tr>
            <tr>
              <td rowSpan={3}>アドバイザー承諾後</td>
              <td>①トーク開始まで残り時間48時間以上</td>
              <td>100%</td>
            </tr>
            <tr>
              <td>②トーク開始まで残り時間24時間以上48時間未満</td>
              <td>50%</td>
            </tr>
            <tr>
              <td>③トーク開始まで残り時間24時間未満</td>
              <td>0%</td>
            </tr>
            <tr>
              <td rowSpan={3}>アドバイザー</td>
              <td colSpan={2}>①トーク開始まで残り時間48時間以上</td>
              <td>100%</td>
            </tr>
            <tr>
              <td colSpan={2}>②トーク開始まで残り時間24時間以上48時間未満</td>
              <td>100%</td>
            </tr>
            <tr>
              <td colSpan={2}>③トーク開始まで残り時間24時間未満</td>
              <td>100%</td>
            </tr>
            <tr>
              <th rowSpan={2}>ノーショー</th>
              <td colSpan={3}>ユーザー</td>
              <td>0%</td>
            </tr>
            <tr>
              <td colSpan={3}>アドバイザー</td>
              <td>100%</td>
            </tr>
            <tr>
              <th rowSpan={2}>システム異常申告</th>
              <td colSpan={3}>アドバイザー未同意</td>
              <td>0%</td>
            </tr>
            <tr>
              <td colSpan={3}>アドバイザー同意</td>
              <td>100%</td>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};

export default Cancellation;
