import React from "react";
import Pagination from "react-js-pagination";
import "../../assets/styles/pagination.css";

const PaginationComponent = ({ page, count, setPage }: any) => {
  return (
    <div>
      <Pagination
        activePage={page}
        itemsCountPerPage={10}
        totalItemsCount={count}
        pageRangeDisplayed={5}
        prevPageText={<i className="fa-solid fa-chevron-left"></i>}
        nextPageText={<i className="fa-solid fa-chevron-right"></i>}
        onChange={setPage}
      />
    </div>
  );
};

export default PaginationComponent;
