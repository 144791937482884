import React, { useEffect } from "react";
import type { FunctionComponent } from "react";

import Navbar from "../components/Navbars/BlackNavbar";
import Footer from "../components/Footers/Footer";

import "../assets/styles/fancy.css";
import About from "../components/Faq/About";
import Trouble from "../components/Faq/Trouble";
import UsingApp from "../components/Faq/UsingApp";
import Money from "../components/Faq/Money";
import Account from "../components/Faq/Account";

const Faq: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <main>
        <section className="pb-20 relative block bg-blueGray-100">
          <div className="container mx-auto px-4 pt-24 lg:pt-24 md:px-20 lg:px-40 xl:px-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <span className="text-2xl md:text-3xl lg:text-4xl font-semibold text-blueGray-600 mb-12">
                  よくあるご質問
                </span>
              </div>
            </div>
            <About></About>
            <UsingApp></UsingApp>
            <Trouble></Trouble>
            <Account></Account>
            <Money></Money>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Faq;
