import React, { useEffect, useState } from "react";
import type { FunctionComponent } from "react";

// components
import Recommend from "../components/Recommend/Recommend";
import Main from "../components/Main/Main";
import AdvisorMerit from "../components/Merit/AdvisorMerit";
import UserMerit from "../components/Merit/UserMerit";
import Navbar from "../components/Navbars/WhiteNavbar";
import Footer from "../components/Footers/Footer";
import Convenient from "../components/Convenient/Convenient";
import Object from "../components/Object/Object";
import Example from "../components/Example/Example";
import MobileDownload from "../components/Snackbar/MobileDownload";

const Home: FunctionComponent = () => {
  const [snackbar, setSnackbar] = useState(true);
  const showSnackbar = () => {
    setSnackbar(!snackbar);
  };
  let timeOut: NodeJS.Timeout;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const show = () => {
      clearTimeout(timeOut);
      timeOut = setTimeout(function () {
        setSnackbar(false);
      }, 10000);
    };
    show();
  });

  return (
    <>
      <Navbar transparent />
      <main>
        <Main></Main>
        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <Object></Object>
            <Recommend></Recommend>
          </div>
        </section>
        <section className="relative pt-20">
          <Convenient></Convenient>
        </section>
        <section className="pt-20">
          <AdvisorMerit></AdvisorMerit>
        </section>
        <section>
          <UserMerit></UserMerit>
        </section>
        <section className="relative block bg-blueGray-800">
          <Example></Example>
        </section>
      </main>
      <Footer />
      {snackbar ? (
        <div className="flex justify-center">
          <MobileDownload
            snackbar={snackbar}
            showSnackbar={showSnackbar}
          ></MobileDownload>
        </div>
      ) : null}
    </>
  );
};
export default Home;
