import React from "react";
import {
  CalendarDaysIcon,
  ClockIcon,
  LockClosedIcon,
  CurrencyYenIcon,
} from "@heroicons/react/24/outline";

export default function AdvisorMerit() {
  const features = [
    {
      name: "収益性",
      description:
        "自分が持つ情報を共有することができて収入を手に入れられます。",
      icon: CurrencyYenIcon,
    },
    {
      name: "自由",
      description:
        "空いた時間や休みの日を利用して他人とのコミュニケーション活動ができます。",
      icon: ClockIcon,
    },
    {
      name: "匿名性",
      description:
        "人が少ない会社や機関で働いているアドバイザーの場合、特定ができるため、匿名で活動ができます。",
      icon: LockClosedIcon,
    },
    {
      name: "時間設定",
      description: "アドバイスできる時間や曜日をご自由に設定ができます。",
      icon: CalendarDaysIcon,
    },
  ];
  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-lg md:text-2xl font-semibold leading-7 text-lightBlue-500">
              メリット
            </h2>
            <p className="mt-2 text-xl md:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              アドバイザー様
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl text-start">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-10 md:pl-16">
                  <dt className="text-lg md:text-2xl font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-7 w-7 md:h-10 md:w-10 items-center justify-center rounded-lg bg-lightBlue-500">
                      <feature.icon
                        className="h-4 w-4 md:h-6 md:w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 text-sm md:text-base leading-7 font-semibold text-blueGray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
