import list from "../../assets/img/list/list.svg";
import recommend from "../../assets/img/recommend/recommend.png";
import React from "react";

export default function Recommend() {
  return (
    <>
      <div className="flex flex-wrap items-center">
        <div
          className="w-full md:w-5/12 px-4 mr-auto ml-auto"
          style={{ textAlign: "start" }}
        >
          <div className="text-blueGray-500 text-center inline-flex items-center justify-center w-7 h-7 md:w-10 md:h-10 mb-6 shadow-lg rounded-lg bg-lightBlue-300">
            <img src={list} alt="" />
          </div>
          <h3 className="text-xl md:text-3xl mb-2 font-semibold leading-normal">
            こんな人におすすめ！
          </h3>
          <p className="text-sm md:text-lg font-light leading-relaxed mt-4 mb-4 font-semibold text-blueGray-600">
            就職・転職・スキルアップにお悩みないですか？
          </p>
          <p className="text-sm md:text-lg font-light leading-relaxed mt-0 mb-4 font-semibold text-blueGray-600">
            DanGoTalkを使って解消しましょう！
          </p>
        </div>

        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-blueGray-500 w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
            <img
              alt="..."
              src={recommend}
              className="w-full align-middle rounded-t-lg bg-white"
            />
            <blockquote className="relative p-5 mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                className="absolute left-0 w-full block h-95-px -top-94-px"
              >
                <polygon
                  points="-30,95 583,95 583,65"
                  className="text-lightBlue-500 fill-current"
                ></polygon>
              </svg>
              <h4 className="text-sm md:text-xl font-bold text-white text-center">
                あなたのお悩みのパズルを解く
              </h4>
            </blockquote>
          </div>
        </div>
      </div>
    </>
  );
}
