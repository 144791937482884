import "../assets/styles/list.css";
import type { FunctionComponent } from "react";
import { useEffect } from "react";

const Privacy: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto my-6 p-3">
        <div>
          <span className="text-xl md:text-2xl lg:text-3xl font-semibold mt-4">
            DanGoTalk プライバシーポリシー
          </span>
        </div>
        <div className="mt-5">
          <span className="text-start text-xs md:text-sm lg:text-md mx-auto">
            株式会社ｄａＯｎ（以下、「当社」といいます）は、当社のインターネットサービス「DanGoTalk」（以下、「本サービス」といいます）を
            提供するにあたり、必要となる情報をお客様（本サービスの利用者及び本サービスの閲覧者を総称するものです。以下、「お客様」といいます）
            から取得します。当社は、お客様から取得した情報（以下「取得情報」）について、それらに含まれる個人情報（個人情報保護法第2条第1項に
            定義されるものを意味します）の保護に最大限の注意を払います。当社の取得情報および個人情報の取り扱いに対する考え方は以下の通りです。
            なお、当プライバシーポリシーにおける用語は、当プライバシーポリシーに定義されるもののほか、本サービスの利用規約等において定義される
            意味によるものとします。
          </span>
        </div>
        <ul className="mt-5">
          <li className="text-start text-xs md:text-sm lg:text-md">
            <span className="font-semibold pl-4 pt-2">
              第1条（取得する情報）
            </span>
            <ol className="order-list">
              <li className="lists">
                本サービスの利用にあたり、以下の情報をご提供いただく場合があります。
                <ol className="order-list">
                  <li className="ol-in-list">
                    お客様のお名前、生年月日、性別、その他の特定の個人を識別することができる情報
                  </li>
                  <li className="ol-in-list">
                    特定の個人情報に結びついて使用されるご住所、電話番号、所属する会社その他の組織の名称、所属する部署の名称、ログインID、ニックネーム、電子メールアドレス、パスワード等の会員に関する情報
                  </li>
                  <li className="ol-in-list">
                    クレジットカード番号、その他のクレジットカードに関する情報、銀行口座番号
                  </li>
                  <li className="ol-in-list">お客様の本人確認に関する情報</li>
                  <li className="ol-in-list">
                    その他当社が定める入力フォームにお客様が入力する情報
                  </li>
                </ol>
              </li>
              <li className="lists">
                当社は、個人情報以外に、お客様のIPアドレス、クッキー情報やお客様が閲覧したページ、お客様の利用環境などの情報をお客様のブラウザから自動的に受け取り、サーバーに記録します。
              </li>
              <li className="lists">
                当社は、提携先やその他の第三者から、お客様または第三者の個人情報や信用情報を含む通知を受け取り、規約違反行為の調査等のため、公開された個人情報を正当な手段により取得する場合があります。
              </li>
              <li className="lists">
                当社は、あらかじめお客様の同意を得ないで、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します）を取得しません。
              </li>
            </ol>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第2条（個人情報の利用目的）
            </span>

            <ol className="order-list mt-4">
              <li>
                当社は個人情報を以下の目的に利用します。
                <ol className="order-list">
                  <li className="ol-in-list">
                    お客様に本サービスを提供するため
                  </li>
                  <li className="ol-in-list">
                    お客様において、興味があると思われる情報を掲示するなど、各お客様に最適化した情報を本サービス上に表示するため
                  </li>
                  <li className="ol-in-list">
                    当社のサービスを向上させることを目的として、サービスの利用状況を分析するため
                  </li>
                  <li className="ol-in-list">
                    お客様または第三者に本サービスについて説明するため、お客様の統計情報を用いるため
                  </li>
                  <li className="ol-in-list">
                    お客様または第三者への連絡を行うなど、本人確認、規約違反行為の調査および対応、問い合わせ内容の調査など、個別の処理や業務のため
                  </li>
                  <li className="ol-in-list">
                    当社の商品・サービス、および、当社がお客様に有用だと考える企業・団体の商品やサービス、イベント等のご案内のため
                  </li>
                  <li className="ol-in-list">
                    本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                  </li>
                  <li className="ol-in-list">
                    本サービスに関する規約等の変更などを通知するため
                  </li>
                  <li className="ol-in-list">
                    取得した閲覧履歴等の本サービスの利用状況の調査を行い、当社のサービスの改善、新サービスの開発等に役立てるため
                  </li>
                  <li className="ol-in-list">
                    当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため
                  </li>
                  <li className="ol-in-list">
                    その他、上記利用目的に付随する目的のため
                  </li>
                </ol>
              </li>
              <li>
                当社は上記以外を目的として、個人情報を閲覧、利用することはありません。
              </li>
            </ol>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第3条（個人情報の開示・訂正・利用停止等の手続）
            </span>
            <div>
              <span className="mt-4">
                当社は、お客様ご本人から、法令等に定める理由によって、個人情報の内容の訂正、追加もしくは削除又は第三者提供記録の確認
                を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、
                遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正、追加または削除等法令上必要な措置を行い、その旨ご本人に
                通知します。
              </span>
            </div>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第4条（個人情報の第三者への提供）
            </span>
            <div className="mt-4">
              <span>
                当社は、以下に定めるところにいずれかに該当する場合、個人情報を第三者に提供します。
              </span>
            </div>
            <ol className="order-list">
              <li className="ol-in-list">
                本サービスを提供するために当社が必要と判断した、本サービス上での情報の提供の場合
                お客様は、提供された他のお客様の情報を、本サービスの利用規約に従った本サービスの利用に必要な範囲でのみ利用するものとし、
                それぞれのお客様の事前の同意なく、他のお客様の情報を第三者に提供してはなりません。
              </li>
              <li className="ol-in-list">
                当社がサービスの運営および提供において必要と判断した場合
              </li>
              <li className="ol-in-list">
                商品の配送、代金決済、お客様からのお問い合わせへの対応、当社からお客様へのお問い合わせ、関連するアフターサービスの提供等のために、配送業者、決済代行業者、業務委託先その他の第三者に提供する場合
              </li>
              <li className="ol-in-list">
                第三者との紛争解決のため、または本サービスのユーザーもしくは第三者の権利や利益等を守るために情報提供が必要と当社が判断した場合
              </li>
              <li className="ol-in-list">
                本サービスの利用状況等を調査・分析するため、当社がかかる調査・分析等を委託する第三者に提供する場合
              </li>
              <li className="ol-in-list">
                当社や第三者の広告の配信または表示のため、第三者に提供する場合
              </li>
              <li className="ol-in-list">
                当社や第三者の提供するサービス内に商品の情報を配信または表示するため、第三者に提供する場合
              </li>
              <li className="ol-in-list">
                学術研究の目的のため、学術研究機関に提供する場合
              </li>
              <li className="ol-in-list">
                クレジットカード発行会社が行う不正利用検知および不正利用防止のため、お客さまの出品や購買の履歴、
                本サービスをご利用するにあたってご登録いただいた情報、本サービスのご利用の状況等を、
                お客様がご利用されているカード発行会社に提供する場合
              </li>
              <li className="ol-in-list">
                その他、
                当社が個人情報の提供が必要と判断した場合に、お客様の同意を得た上で、個人情報を提供することがあります。
              </li>
            </ol>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第5条（個人情報の管理およびセキュリティ）
            </span>
            <div className="mt-4">
              <span>
                当社では、収集させていただいた個人情報を、一般の利用者がアクセスできない環境下にある、
                ファイヤーウォールで保護されたサーバーにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは
                漏洩の防止その他の個人データの安全管理のために必要かつ適切な措置を講じます。なお、当社では、
                当社が信頼に足ると判断し個人情報の守秘義務契約を結んだ企業に、個人情報の取り扱いを委託する場合がありますが、
                この場合にも、個人情報は本プライバシーポリシーのもとで保護されます。
              </span>
            </div>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第6条（クッキーの使用）
            </span>

            <ol className="order-list mt-4">
              <li>
                当社はユニークお客様数の測定、およびお客様認証のためクッキーを使用しています。クッキーの仕組みは以下の通りです。
                <ol className="order-list">
                  <li className="ol-in-list">
                    お客様が本サービスを閲覧する際、当社はお客様のブラウザに対してお客様を識別するためのクッキーを送信します。
                  </li>
                  <li className="ol-in-list">
                    お客様がブラウザの設定でクッキーの受信を許可している場合、お客様が使用しているコンピュータ内にクッキー情報が保管されます。
                  </li>
                  <li className="ol-in-list">
                    お客様がブラウザの設定でクッキーの送信を許可している場合、ウェブサーバーはお客様のブラウザからクッキー情報を受け取ります。
                    ただしお客様のブラウザはプライバシー保護のため、そのウェブサイトのサーバー自身が送信したクッキーのみ送信を行います。
                  </li>
                  <li className="ol-in-list">
                    お客様はクッキーの送受信に関する設定で、「クッキーの送受信を拒否する」といった選択を行うことでクッキーの
                    送受信を拒否することができます。この場合、本サービス内で、クッキーが必要なサービスを利用することができなくなります。
                  </li>
                </ol>
              </li>
              <li>
                本サービス上に表示された第三者の広告によりクッキーが使用される場合があります。この場合、広告主によるクッキーの利用は、
                広告主のプライバシーの考え方に従って行われます。
              </li>
              <li>
                当社ウェブサイトでは、ウェブサイト又はアプリケーションを改善する目的でGoogle
                Analyticsを利用し、
                それに伴いかかる目的でお客様の情報を利用しています。Google
                Analyticsは、クッキーを利用してお客様の情報を収集します。 Google
                Analyticsの利用規約及びプライバシーポリシーに関する説明については、以下のGoogle
                Analyticsのサイトをご覧ください。
              </li>
              <li className="lists">
                当社は、あらかじめお客様の同意を得ないで、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します）を取得しません。
                <ol className="order-list">
                  <li className="ol-in-list">
                    <a
                      href="https://policies.google.com/technologies/partner-sites"
                      className="text-lightBlue-600"
                    >
                      Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用
                    </a>
                  </li>
                  <li className="ol-in-list">
                    <a
                      href="https://tools.google.com/dlpage/gaoptout?hl=jp"
                      className="text-lightBlue-600"
                    >
                      Googleアナリティクス オプトアウト ブラウザ アドオン
                    </a>
                  </li>
                  <li className="ol-in-list">
                    <a
                      href="https://support.google.com/analytics/answer/3450482?hl=ja"
                      className="text-lightBlue-600"
                    >
                      Googleアナリティクスの広告向け機能について
                    </a>
                  </li>
                </ol>
              </li>
              <li className="lists">
                本サービスはクッキーを活用したGoogle アナリティクス
                リマーケティングを使用して、オンライン広告を掲載しております。
                <ol className="order-list">
                  <li className="ol-in-list">
                    Google
                    を含む第三者配信事業者が、インターネット上のさまざまなサイトで本サービスの広告を掲載しております。
                  </li>
                  <li className="ol-in-list">
                    Google
                    を含む第三者配信事業者がファーストパーティ・クッキーとサードパーティ・クッキーを組み合わせて、過去にお客様がサイトにアクセスしたときの行動に基づいて広告を作成、最適化、配信しております。
                  </li>
                </ol>
              </li>
              <li className="lists">
                本サービスはクッキーを活用して、お客様にあわせた表示・配信コンテンツのカスタマイズを行っております。
              </li>
            </ol>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第7条（免責・注意事項）
            </span>

            <ol className="order-list">
              <li className="lists">
                当社は、本サービスにリンクされている他の事業者または個人のウェブサイトにおけるお客様の個人情報等の保護について、
                責任を負うものではありません。お客様ご自身にて当該ウェブサイトの内容をよくご確認の上で、閲覧およびご利用をお願いいたします。
              </li>
              <li className="lists">
                当社は、お客様の個人情報を不正なアクセスから守るため､データの保護が必要であると判断した場合、データ転送にSSL(Secure
                Socket
                Layer)という業界標準の暗号方式を使用しています。SSL非対応ブラウザによる本ウェブサイト等上での登録、
                申込もしくは注文、または電子メールもしくは郵便により個人情報をご提供いただく場合は、当社への情報到達過程にて、
                当社の管理外の第三者に閲覧、窃取される危険性が高まりますので、これらの危険性をご了解の上これらの手段をご利用ください。
              </li>
              <li className="lists">
                当社によるセキュリティ施策にもかかわらず、ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合については、当社は責任を負いかねますのでご了承ください。
                お客様ご本人を確認するための情報につきましては、お客様にて、紛失、忘失または第三者に知られることのないよう厳重な管理をお願いいたします。
              </li>
            </ol>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第8条（プライバシーポリシーの改定）
            </span>

            <ol className="order-list mt-4">
              <li className="lists">
                当社は当プライバシーポリシーの全部または一部を改定することがあります。
                重要な変更がある場合には、本サービス上などで分かりやすい方法でお知らせします。
                この場合、利用者の個人情報は、改定後のプライバシーポリシーに従って取り扱われることになります。
              </li>
              <li className="lists">
                お客様は本サービスをご利用になる前に、必ず最新の「プライバシーポリシー」をご確認ください。
              </li>
            </ol>
          </li>
          <li className="text-start text-xs md:text-sm lg:text-md mt-5">
            <span className="font-semibold pl-4 pt-2">
              第9条（お問い合わせ先）
            </span>
            <div className="mt-4">
              本プライバシーポリシーに関するお問い合わせは、下記のお問い合わせ先までお願いいたします。
              <br />
              〒162-0072 <br />
              東京都新宿区大久保二丁目７－１ 大久保フジビル７Ｆ <br />
              株式会社ｄａＯｎ 個人情報担当者宛
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Privacy;
