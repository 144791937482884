import { API } from "aws-amplify";

export const searchQuery: any = async (
  query: string,
  params: {
    filter?: string | null;
    sort?: [string | null];
    limit?: number | null;
    nextToken?: string | null;
    from?: number | null;
  }
) => {
  try {
    const result = await API.graphql({
      query: query,
      variables: params,
      authMode: "API_KEY",
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};
