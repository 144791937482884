import appstore from "../../assets/img/main/App_Store_Badge_JP.png";
import googleplay from "../../assets/img/main/google-play-badge.png";
import React, { useState } from "react";
import GoogleQRCode from "../Qrcode/GoogleQRCode";
import AppStoreQRCode from "../Qrcode/AppStoreQRCode";
import backgroundPng from "../../assets/img/main/background.png";

export default function Main() {
  const [showAppStore, setShowAppStore] = useState(false);
  const [showGooglePlay, setShowGooglePlay] = useState(false);
  const appFlag = false;
  const changeAppStore = () => {
    setShowAppStore(!appFlag);
  };
  const googleFlag = false;
  const changeGooglePlay = () => {
    setShowGooglePlay(!googleFlag);
  };
  const childAppStoreClose = () => {
    setShowAppStore(!showAppStore);
  };
  const childGooglePlayClose = () => {
    setShowGooglePlay(!showGooglePlay);
  };
  return (
    <>
      <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${backgroundPng})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
          }}
        >
          <span
            id="blackOverlay"
            className="h-full bg-black"
            style={{
              opacity: 0.65,
              width: "100%",
            }}
          ></span>
        </div>
        <div className="container relative mx-auto">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div>
                <h1 className="text-blueGray-200 font-semibold text-2xl md:text-3xl lg:text-4xl">
                  人脈の障壁を乗り越え
                  <br />
                  情報をつなげる
                </h1>
                <p className="mt-4 text-md md:text-lg lg:text-1xl text-blueGray-200">
                  必要な情報を求める人へ届ける
                </p>
              </div>
              <div className="mt-12 md:hidden">
                <a
                  href="https://apps.apple.com/jp/app/dangotalk/id6444250993"
                  className="inline-block"
                >
                  <img
                    alt="Appstore でダウンロード"
                    src={appstore}
                    className="mr-3 w-[100%] max-w-100-px max-h-30-px"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.dangotalk.app&hl=ja&gl=US"
                  className="inline-block ml-5"
                >
                  <img
                    alt="Google Play で手に入れよう"
                    src={googleplay}
                    className="w-[100%] max-w-100-px max-h-30-px"
                  />
                </a>
              </div>
              <div className="mt-12 hidden md:block">
                <button onClick={changeAppStore} className="inline-block mr-3">
                  <img
                    alt="Appstore でダウンロード"
                    src={appstore}
                    className="w-[100%] max-w-150-px max-h-30-px"
                  />
                </button>
                <button
                  onClick={changeGooglePlay}
                  className="inline-block ml-5"
                >
                  <img
                    alt="Google Play で手に入れよう"
                    src={googleplay}
                    className="w-[100%] max-w-150-px max-h-30-px"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      {showAppStore && (
        <AppStoreQRCode
          showAppStore={showAppStore}
          childAppStoreClose={childAppStoreClose}
        />
      )}
      {showGooglePlay && (
        <GoogleQRCode
          showGooglePlay={showGooglePlay}
          childGooglePlayClose={childGooglePlayClose}
        />
      )}
    </>
  );
}
