import {useLocation} from "react-router-dom";
import Navbar from "../components/Navbars/BlackNavbar";
import Footer from "../components/Footers/Footer";
import React, {useEffect, useState} from "react";
import type {GetNoticeQuery} from "../API";
import awsExports from "../aws-exports";
import Chip from "@mui/joy/Chip";
import type {FunctionComponent} from "react";
import {getQuery} from "../utils/useQuerys";
import {updateMutation} from "../utils/useMutation";
import * as mutations from "../graphql/mutations";
import {useParams} from 'react-router-dom';

const NoticeInfo: FunctionComponent = () => {
  const location = useLocation();
  let noticeData: any;
  const pathname = location.pathname.split("/notice/")[1];
  const id = location.state?.id || pathname;
  const [notice, setNotice] = useState<GetNoticeQuery | undefined | any>();
  const [showModal, setShowModal] = useState(false);
  const [input, setInput] = useState();
  const getQuerySql = `query GetNotice($id: ID!) {
    getNotice(id: $id) {
      title
      category {
        category
      }
      content
      imageUrl
      startTime
      viewCount
    }
  }`;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const getNoticeData = async () => {
      noticeData = await getQuery(getQuerySql, id);

      setNotice(noticeData?.data);
      const viewCountData = {
        id: id,
        viewCount: noticeData?.data?.getNotice?.viewCount + 1,
      };
      await updateMutation(mutations.updateNotice, viewCountData);
    };

    getNoticeData();
  }, [getQuerySql, id]);
  return (
    <>
      <Navbar></Navbar>
      <main>
        <section className="pb-20 relative block bg-blueGray-100">
          <div className="container mx-auto px-4 pt-24 lg:pt-24 lg:pb-64 md:px-20 xl:px-64">
            <div className="text-start w-full border-0 border-blueGray-500 rounded-lg bg-white">
              <div
                className="md:flex flex-wrap w-full px-4 py-4"
                style={{
                  borderBottomStyle: "solid",
                  borderBottom: "2px solid lightgray",
                }}
              >
                <div className="p-1 flex flex-wrap text-xs">
                  <div style={{ alignSelf: "center" }}>
                    <Chip
                      size={"sm"}
                      variant="solid"
                      color={
                        notice?.getNotice?.category?.category === "イベント"
                          ? "info"
                          : notice?.getNotice?.category?.category === "アップデート"
                            ? "success"
                            : notice?.getNotice?.category?.category === "メンテナンス"
                              ? "neutral"
                              : "neutral"
                      }
                    >
  <span className="font-bold text-white">
    {notice?.getNotice?.category?.category}
  </span>
                    </Chip>

                  </div>
                </div>
                <div className="ml-2 w-full my-auto py-3">
            <span className="text-md lg:text-xl text-blueGray-600 font-semibold break-words">
              {notice?.getNotice?.title}
            </span>
                </div>
                <div className="p-1 my-auto flex justify-end">
                  <div className="flex">
                    <div className="flex ml-1 text-sm md:text-md text-blueGray-500 mr-2">
                      <i className="fa-sharp fa-regular fa-calendar mx-auto my-auto mr-1 opacity-50"></i>{" "}
                      <span>
                  {new Date(notice?.getNotice?.startTime)
                    .toLocaleString()
                    .replace(/\//g, ".")
                    .split(" ")[0]}
                </span>
                    </div>
                    <div className="flex text-sm md:text-md text-blueGray-500">
                      <i className="fa-solid fa-eye mx-auto my-auto mr-1 text-blueGray-500 opacity-50"></i>{" "}
                      <span>{notice?.getNotice?.viewCount}</span>
                    </div>
                  </div>
                </div>
              </div>
              {notice?.getNotice?.imageUrl && notice?.getNotice?.imageUrl.length > 0 && (
                <>
                  <div className="container px-4">
                    <div className="p-5">
                      <div className="justify-center">
                        <div className="">
                          {notice?.getNotice?.imageUrl.map((url: any) => (
                            <img
                              className="p-2 lg:p-0 mx-auto my-auto"
                              style={{ width: "auto" }}
                              src={`https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${url}`}
                              alt=""
                              onClick={() => {
                                setShowModal(true);
                                setInput(url);
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottomStyle: "solid",
                      borderBottom: "2px solid lightgray",
                    }}
                  ></div>
                </>
              )}
              <div className="px-4 w-full">
                <div className="w-full p-5 text-left">
            <span
              dangerouslySetInnerHTML={{
                __html: notice?.getNotice?.content,
              }}
            ></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>
      {showModal ? (
        <div className="fixed m-auto inset-0 z-50 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-3xl p-4 mx-auto bg-white rounded-md shadow-lg">
              <img
                style={{margin: "auto"}}
                src={`https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com/public/${input}`}
                alt=""
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NoticeInfo;
