export function resizeImage(
  file: File,
  maxWidth: number,
  maxHeight: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const width = image.width;
      const height = image.height;
      const size = file.size;
      // イメージのサイズが1mb以上の場合実行
      if (size > 1048576) {
        let resizeWidth = 0;
        let resizeHeight = 0;
        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }
        if (width > height) {
          resizeHeight = height * (maxHeight / width);
          resizeWidth = maxHeight;
        } else {
          resizeWidth = width * (maxWidth / height);
          resizeHeight = maxWidth;
        }

        const canvas: HTMLCanvasElement = document.createElement("canvas");

        canvas.width = resizeWidth;
        canvas.height = resizeHeight;

        const context = canvas.getContext("2d");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.drawImage(image, 0, 0, resizeWidth, resizeHeight);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.toBlob(resolve, file.type);
      } else {
        resolve(file);
      }
    };
    image.onerror = reject;
  });
}
