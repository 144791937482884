import React, { useEffect, useState } from "react";
import type { FunctionComponent } from "react";
import Navbar from "../components/Navbars/BlackNavbar";
import Footer from "../components/Footers/Footer";
import type { SearchNoticesQuery } from "../API";
import PaginationComponent from "../components/Pagination/PaginationComponent";
import { Link } from "react-router-dom";
import Chip from "@mui/joy/Chip";
import { searchQuery } from "../utils/useSearchable";
import "../assets/styles/notice.css";

const Notice: FunctionComponent = (props) => {
  const [total, setTotal] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(10);
  const [indexOfLastPost, setIndexOfLastPost] = useState(0);
  const [indexOfFirstPost, setIndexOfFirstPost] = useState(0);
  const [currentNotice, setCurrentNotice] = React.useState<
    SearchNoticesQuery | undefined | any
  >();
  const [noticeList, setNoticeList] = React.useState<
    SearchNoticesQuery | undefined | any
  >();
  const [openTab, setOpenTab] = useState(1);
  const [categoryData, setCategoryData] = useState("");
  const searchSql = `query SearchNotices(
    $filter: SearchableNoticeFilterInput
    $sort: [SearchableNoticeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNoticeAggregationInput]
  ) {
    searchNotices(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
      category {
        category
      }
      title
      startTime
      viewCount
      }
      total
      }
     }
      `;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let variables: object;
    categoryData !== ""
      ? (variables = {
          filter: {
            and: [
              {
                isView: { eq: true },
              },
              {
                noticeCategoryId: { eq: categoryData },
              },
            ],
          },
          sort: { field: "startTime", direction: "desc" },
        })
      : (variables = {
          filter: {
            and: [
              {
                isView: { eq: true },
              },
            ],
          },
          sort: { field: "startTime", direction: "desc" },
        });
    const noticeData = async () => {
      const result = await searchQuery(searchSql, variables);
      setNoticeList(result.data);
      setTotal(result.data?.searchNotices?.total);
    };
    setIndexOfLastPost(currentPage * postPerPage);
    setIndexOfFirstPost(indexOfLastPost - postPerPage);
    setCurrentNotice(
      noticeList?.searchNotices?.items.slice(indexOfFirstPost, indexOfLastPost)
    );
    noticeData();
  }, [
    currentPage,
    indexOfLastPost,
    indexOfFirstPost,
    noticeList,
    postPerPage,
    searchSql,
  ]);

  const setPage = (error: any) => {
    setCurrentPage(error);
  };

  return (
    <>
      <Navbar />
      <main>
        <section className="pb-20 relative block bg-blueGray-100">
          <div className="container mx-auto px-4 pt-24 lg:pt-24 md:px-20 lg:px-40 xl:px-64 pb-20">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <span className="text-2xl md:text-3xl lg:text-4xl font-semibold text-blueGray-600 mb-12">
                  お知らせ
                </span>
              </div>
            </div>
            <div className="flex flex-wrap flex-auto text-center mb-3">
              <a
                style={{ fontSize: "4px" }}
                className={
                  "font-bold uppercase px-2 py-1 block leading-normal cursor-pointer " +
                  (openTab === 1
                    ? "text-white bg-lightBlue-600"
                    : "text-lightBlue-600 bg-blueGray-200")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                  setCategoryData("");
                }}
                data-toggle="tab"
                role="tablist"
              >
                全て
              </a>
              <a
                style={{ fontSize: "4px" }}
                className={
                  "font-bold uppercase px-2 py-1 block leading-normal cursor-pointer " +
                  (openTab === 2 ? "bg-maintenance" : "bg-maintenance-clicked")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                  setCategoryData("9250485a-a270-42fb-9042-d0a1e91e0f39");
                }}
                data-toggle="tab"
                role="tablist"
              >
                メンテナンス
              </a>
              <a
                style={{ fontSize: "4px" }}
                className={
                  "font-bold uppercase px-2 py-1 block leading-normal cursor-pointer " +
                  (openTab === 3 ? "bg-update" : "bg-update-clicked")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                  setCategoryData("a3def0e1-acae-4ae0-a838-dbacfdff55ec");
                }}
                data-toggle="tab"
                role="tablist"
              >
                アップデート
              </a>
              <a
                style={{ fontSize: "4px" }}
                className={
                  "font-bold uppercase px-2 py-1 block leading-normal cursor-pointer " +
                  (openTab === 4 ? "bg-event" : "bg-event-clicked")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                  setCategoryData("502a8393-675e-46ee-8175-cb32a36f968c");
                }}
                data-toggle="tab"
                role="tablist"
              >
                イベント
              </a>
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 text-blueGray-700">
              <div className="block w-full overflow-x-auto">
                {currentNotice?.map((notice: any) => {
                  return (
                    <>
                      <div className="notice-wrap my-auto">
                        <ul className="w-[100%]">
                          <li className="hover:bg-white p-3 hidden sm:list-item">
                            <p className="flex w-[100%]">
                              <div className="w-28 mx-auto my-auto">
                                {notice?.category?.category ===
                                "メンテナンス" ? (
                                  <Chip size={"sm"} variant="soft" color={"neutral"}>
                                    <span className="text-xs font-bold">
                                      {notice?.category?.category}
                                    </span>
                                  </Chip>
                                ) : notice?.category?.category ===
                                  "アップデート" ? (
                                  <Chip
                                    color="success"
                                    size={"sm"}
                                    variant="soft"
                                  >
                                    <span className="text-xs font-bold">
                                      {notice?.category?.category}
                                    </span>
                                  </Chip>
                                ) : (
                                  <Chip size={"sm"} variant="soft" color={"info"}>
                                    <span className="text-xs font-bold ">
                                      {notice?.category?.category}
                                    </span>
                                  </Chip>
                                )}
                              </div>
                              <span className="w-10/12 ml-1 pl-6 md:ml-4 font-semibold text-blueGray-600">
                                {notice && (
                                <Link
                                  to={`${notice?.id}`}
                                  state={{ id: notice?.id }}
                                >
                                  <span>{notice?.title}</span>
                                </Link>
                                )}
                              </span>

                              <span className="flex ml-1 my-auto text-xs text-blueGray-400">
                                <i className="fa-sharp fa-regular fa-calendar mx-auto my-auto mr-1"></i>{" "}
                                {
                                  new Date(notice?.startTime)
                                    .toLocaleString()
                                    .replace(/\//g, ".")
                                    .split(" ")[0]
                                }
                              </span>
                            </p>
                          </li>
                          <li className="hover:bg-gray-200 p-3 pb-0mx-auto my-auto list-item inline-block sm:hidden h-auto">
                            <p className="flex w-[100%] text-xs font-semibold">
                              <span className="w-3/12 my-auto">
                                {notice?.category?.category ===
                                "メンテナンス" ? (
                                  <span className="text-lightBlue-300">
                                    {notice?.category?.category}
                                  </span>
                                ) : notice?.category?.category ===
                                  "アップデート" ? (
                                  <span className="text-green-400">
                                    {notice?.category?.category}
                                  </span>
                                ) : (
                                  <span className="text-purple-400">
                                    {notice?.category?.category}
                                  </span>
                                )}
                              </span>
                              <span className="font-semibold text-xs text-blueGray-600 my-auto w-10/12 mr-3">
                                <Link
                                  to={`${notice?.title}`}
                                  state={{ id: notice?.id }}
                                >
                                  <span>{notice?.title}</span>
                                </Link>
                              </span>
                              <span className="flex text-xs my-auto text-blueGray-400 float-left">
                                <i className="fa-sharp fa-regular fa-calendar mr-1"></i>{" "}
                                <span>
                                  {
                                    new Date(notice?.startTime)
                                      .toLocaleString()
                                      .replace(/\//g, ".")
                                      .split(" ")[0]
                                  }
                                </span>
                              </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {total > 10 ? (
              <div className="flex flex-wrap text-center mx-auto">
                <div className="w-full">
                  <PaginationComponent
                    page={currentPage}
                    count={total}
                    setPage={setPage}
                  ></PaginationComponent>
                </div>
              </div>
            ) : null}
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Notice;
